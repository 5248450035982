import * as React from "react";
import Box from "@mui/material/Box";
import Background from "../../components/ui/Background";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";

import Header from "../../components/ui/Header";
import VisualMessage from "../../components/ui/VisualMessage";
import useLabels from "../../labels/useLabels";
import StepEmail from "./StepEmail";
import StepPersonalInformation from "./StepPersonalInformation";
import StepAddress from "./StepAddress";
import StepEmployerInformation from "./StepEmployerInformation";
import StepTermsAndConditions from "./StepTermsAndConditions";
import StepPassword from "./StepPassword";
import OnboardingAccordion from "../../components/ui/OnboardingAccordion";
import { useNavigate } from "react-router-dom";
import HttpService from "../../services/HttpService";
import UserService from "../../services/UserService";
import { useMutation } from "@tanstack/react-query";
import { useLocation, useParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import OnboardingEmployerAttributeService from "../../services/config/OnboardingEmployerAttributeService";

export default function MainOnboarding({
  directEmail,
  directEmployerId,
  isMobileAppProp,
}) {
  const { getLabel } = useLabels();
  const [employerId, setEmployerId] = React.useState(null);
  const [step, setStep] = React.useState("1");
  const [loaded, setLoaded] = React.useState(false);
  const [backgroundImage, setBackgroundImage] = React.useState(null);
  const [bgDesktop2, setBgDesktop2] = React.useState(null);
  const [visualMessage, setVisualMessage] = React.useState();
  const [email, setEmail] = React.useState("");
  const [token, setToken] = React.useState("");
  const [bagContent, setBagContent] = React.useState({});
  const [expanded, setExpanded] = React.useState(step);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const jwt = queryParams.get("jwt");
  const isMobileApp = queryParams.has("mobileApp")
    ? queryParams.get("mobileApp") === "true"
    : isMobileAppProp ?? false;
  const [logoEmployer, setLogoEmployer] = React.useState(null);
  const [completeName, setCompleteName] = React.useState(null);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const navigate = useNavigate();

  const sendMessageToAppWebView = (tokenInfo) => {
    const message = { type: "login", payload: { tokenInfo } };
    if (window.messageHandler) {
      window.messageHandler.postMessage(JSON.stringify(message));
    } else {
      console.error("messageHandler channel not available");
    }
    //window.postMessage(JSON.stringify(message), "*"); // Use "*" for targetOrigin if you don't know the exact origin of the Flutter app, but it's better to specify it if possible for security reasons.
  };

  const LoginMutation = useMutation(
    (loginData) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + `/login`,
        loginData,
        { avoidBearer: true }
      );
    },
    {
      onSuccess: (value) => {
        UserService.setTokens(
          value.data.token,
          value.data.refreshToken,
          value.data.idToken
        );
        if (isMobileApp) {
          var tokenInfo = {
            userId: email,
            token: value.data.token,
            refreshToken: value.data.refreshToken,
            expiresIn: value.data.expiresIn,
            refreshExpiresIn: value.data.refreshExpiresIn,
          };

          sendMessageToAppWebView(tokenInfo);
        } else {
          UserService.updateToken(() => {
            navigate("/home");
          });
        }
      },
      onError: (values) => {},
    }
  );

  React.useEffect(() => {
    directEmployerId
      ? setEmployerId(directEmployerId)
      : bagContent?.employerId
      ? setEmployerId(bagContent?.employerId)
      : setEmployerId(null);
    bagContent?.firstName &&
      bagContent?.lastName &&
      setCompleteName(bagContent?.firstName + " " + bagContent?.lastName);
  }, [bagContent, directEmployerId]);

  const stringToBoolean = (str) => str === "true";

  const onboardingEmployerAttributes = OnboardingEmployerAttributeService(
    employerId,
    "PAYCARD_USE_DEFAULT_ADDRESS"
  ).attribute;

  React.useEffect(() => {
    if (bagContent && bagContent.step) {
      if (
        (Number(step) === 2 || Number(step) === 3 || Number(step) === 4) &&
        (bagContent?.employerIdDisabled === "true" ||
          (directEmployerId !== undefined &&
            directEmployerId !== null &&
            directEmployerId !== "" &&
            bagContent?.employeeIdDisabled === "true"))
      ) {
        if (
          stringToBoolean(bagContent?.ssnItinDisabled) &&
          stringToBoolean(bagContent?.ssnItinDisabled) &&
          stringToBoolean(bagContent?.firstNameDisabled) &&
          stringToBoolean(bagContent?.middleNameDisabled) &&
          stringToBoolean(bagContent?.lastNameDisabled) &&
          stringToBoolean(bagContent?.mobilePhoneDisabled) &&
          stringToBoolean(bagContent?.homePhoneDisabled) &&
          stringToBoolean(bagContent?.dobDisabled)
        ) {
          if (
            (bagContent?.cardType === "PAYCARD" ||
              bagContent?.cardType === "CORPORATE_EXPENSE") &&
            !!onboardingEmployerAttributes?.PAYCARD_USE_DEFAULT_ADDRESS
          ) {
            setStep(6);
          } else {
            if (step !== 4) setStep(4);
          }
        } else {
          if (step !== 3 && step !== 4) setStep(3);
        }
      } else {
        if (Number(step) === 1) setStep(bagContent.step);
      }
    }
  }, [bagContent, directEmployerId, onboardingEmployerAttributes, step]);

  React.useEffect(
    () => {
      async function getInitials(subdomain) {
        const decodedToken = jwt && jwt_decode(jwt);
        const { cardType: cardTypeFromUrl, employerId } = decodedToken || {};
        let cardTypeImageUrlPart = "2";
        if (cardTypeFromUrl) {
          cardTypeImageUrlPart = cardTypeFromUrl;
        }
        try {
          const [backgroundImage, bgImage2] = await Promise.all([
            import(`../../images/bg_login.${subdomain}.jpg`),
            import(
              `../../images/bg_desktop.${cardTypeImageUrlPart}.${subdomain}.jpg`
            ),
          ]);
          setBackgroundImage(backgroundImage.default);
          setBgDesktop2(bgImage2.default);
        } catch (error) {
          getInitials("rv");
        }
        try {
          const employerIdForLogo = directEmployerId ?? employerId;

          if (employerIdForLogo) {
            setLogoEmployer(
              require(`../../images/employer/logo.employer.${employerIdForLogo}.svg`)
            );
          }
          // eslint-disable-next-line import/no-dynamic-require, global-require
        } catch (error) {
          // continue regardless of error, so no specific image for the employer
        }
      }

      getInitials(window.subdomain);
    },
    [],
    jwt
  );

  React.useEffect(() => {
    if (directEmail !== undefined && directEmail !== null && directEmail !== "")
      setEmail(directEmail);
  }, [directEmail]);

  React.useEffect(() => {
    const decodedToken = jwt && jwt_decode(jwt);
    const { email: emailFromUrl = null } = decodedToken || {};
    if (emailFromUrl) {
      setEmail(emailFromUrl);
    }
  }, [jwt]);
  return (
    <div style={{ display: loaded || isMobileApp ? "block" : "none" }}>
      <Background image={backgroundImage} onLoad={() => setLoaded(true)}>
        {visualMessage && (
          <VisualMessage
            title={visualMessage.title}
            message={visualMessage.message}
            severity={visualMessage.severity}
            onClose={visualMessage.onClose}
          />
        )}
        <main>
          <Box>
            <Grid container alignItems="flex-start" sx={{ height: "100vh" }}>
              <Grid container sx={{ height: "600px" }}>
                <Header isClosable={!isMobileApp} showLogo={!isMobileApp} />
                <Grid
                  item
                  xs={6}
                  sx={{
                    textAlign: "center",
                    backgroundImage: `url(${bgDesktop2})`,
                    backgroundSize: "cover",
                    backgroundPosition: "40% center",
                    width: "100%",
                    height: "100vh",
                    display: { xs: "none", sm: "block" },
                  }}
                />
                <Grid item xs={12} sm={6} sx={{ textAlign: "center" }}>
                  <Grid
                    container
                    justifyContent="center"
                    sx={{ height: "100vh" }}
                  >
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="flex-start"
                    >
                      <main>
                        <Box>
                          <Grid
                            alignItems="center"
                            container
                            justifyContent="center"
                            padding={0}
                            pt={1}
                            sx={{
                              width: "100%",
                              height: "100%",
                            }}
                          >
                            <Grid item xs={12} mb={2}>
                              <Typography color={"primary"} variant="h6">
                                {getLabel(
                                  "onboarding.welcome.line1",
                                  "Easy Signup. Great Experience."
                                )}
                              </Typography>
                              {logoEmployer && (
                                <Box
                                  component="img"
                                  sx={{
                                    marginTop: "1rem",
                                    marginBottom: "0.5rem",
                                    width: 200,
                                    maxHeight: { xs: 233, md: 167 },
                                    maxWidth: { xs: 350, md: 250 },
                                  }}
                                  alt="Company Logo"
                                  src={logoEmployer}
                                />
                              )}
                              <Typography color={"black"}>
                                {getLabel(
                                  "onboarding.welcome.line2",
                                  "Let's begin creating your account. Just follow these simple steps."
                                )}
                              </Typography>
                            </Grid>
                            <OnboardingAccordion
                              currentStep={step}
                              thisStep={1}
                              title={
                                getLabel(
                                  "onboarding.acordion01.label",
                                  "Email Verification"
                                ) + (email ? ` (${email})` : "")
                              }
                              expanded={expanded}
                              handleChange={handleChange}
                            >
                              <StepEmail
                                onSave={(email, data) => {
                                  setEmail(email);
                                  setToken(data.data.token);
                                  setBagContent(data.data.data);
                                }}
                                directEmail={directEmail || email}
                                processStepUrl="/onboarding/step1"
                                from="onboarding"
                                isMobileApp={isMobileApp}
                              />
                            </OnboardingAccordion>
                            <OnboardingAccordion
                              currentStep={step}
                              thisStep={2}
                              title={getLabel(
                                "onboarding.acordion05.label",
                                "Employer details"
                              )}
                              expanded={expanded}
                              handleChange={handleChange}
                            >
                              <StepEmployerInformation
                                email={email}
                                token={token}
                                bagContent={bagContent}
                                directEmployerId={directEmployerId}
                                onSave={(employerId) => {
                                  setEmployerId(employerId);
                                  setStep(3);
                                }}
                              />
                            </OnboardingAccordion>
                            <OnboardingAccordion
                              currentStep={step}
                              thisStep={3}
                              title={getLabel(
                                "onboarding.acordion02.label",
                                "Personal Information"
                              )}
                              expanded={expanded}
                              handleChange={handleChange}
                            >
                              <StepPersonalInformation
                                email={email}
                                token={token}
                                bagContent={bagContent}
                                employerId={employerId}
                                onSave={(completeName) => {
                                  setStep(4);
                                  setCompleteName(completeName);
                                }}
                              />
                            </OnboardingAccordion>
                            {((bagContent?.cardType !== "PAYCARD" &&
                              bagContent?.cardType !== "CORPORATE_EXPENSE") ||
                              !onboardingEmployerAttributes?.PAYCARD_USE_DEFAULT_ADDRESS) && (
                              <>
                                <OnboardingAccordion
                                  currentStep={step}
                                  thisStep={4}
                                  title={getLabel(
                                    "onboarding.acordion03.label",
                                    "Address Details"
                                  )}
                                  expanded={expanded}
                                  handleChange={handleChange}
                                >
                                  <StepAddress
                                    email={email}
                                    token={token}
                                    employerId={employerId}
                                    bagContent={bagContent}
                                    currentStep="4"
                                    onSave={() => {
                                      setStep(5);
                                    }}
                                  />
                                </OnboardingAccordion>
                                <OnboardingAccordion
                                  currentStep={step}
                                  thisStep={5}
                                  title={getLabel(
                                    "onboarding.acordion04.label",
                                    "Mailing Address"
                                  )}
                                  expanded={expanded}
                                  handleChange={handleChange}
                                >
                                  <StepAddress
                                    email={email}
                                    employerId={employerId}
                                    token={token}
                                    bagContent={bagContent}
                                    currentStep="5"
                                    onSave={() => {
                                      setStep(6);
                                    }}
                                  />
                                </OnboardingAccordion>
                              </>
                            )}
                            <OnboardingAccordion
                              currentStep={step}
                              thisStep={6}
                              title={getLabel(
                                "onboarding.acordion06.label",
                                "Terms and Conditions Acceptance"
                              )}
                              expanded={expanded}
                              handleChange={handleChange}
                            >
                              <StepTermsAndConditions
                                email={email}
                                token={token}
                                currentStep={step}
                                bagContent={bagContent}
                                onCheck={setStep}
                                employerId={employerId}
                                completeName={completeName}
                                onSave={() => {
                                  setStep(7);
                                }}
                              />
                            </OnboardingAccordion>
                            <OnboardingAccordion
                              currentStep={step}
                              thisStep={7}
                              title={getLabel(
                                "onboarding.acordion07.label",
                                "Password Creation"
                              )}
                              expanded={expanded}
                              handleChange={handleChange}
                            >
                              <StepPassword
                                email={email}
                                token={token}
                                bagContent={bagContent}
                                loginMutationIsLoading={LoginMutation.isLoading}
                                onSave={(password) => {
                                  const values = {
                                    username: email,
                                    password: password,
                                    token: token,
                                    type: "onboarding",
                                    rememberMe: "",
                                  };
                                  LoginMutation.mutate({
                                    ...values,
                                    isMobileApp: isMobileApp,
                                  });
                                }}
                              />
                            </OnboardingAccordion>
                          </Grid>
                        </Box>
                      </main>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </main>
      </Background>
    </div>
  );
}
