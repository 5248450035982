/* Password Creation */

import * as React from "react";
import {
  Grid,
  TextField,
  MenuItem,
  Typography,
  Dialog,
  DialogTitle,
  Box,
  DialogContent,
  DialogActions,
  useMediaQuery,
  Divider,
} from "@mui/material";
import LoadingButton from "../../components/ui/LoadingButton";
import useLabels from "../../labels/useLabels";
import Own from "../sendMoney/Own";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import * as validatorYup from "yup";
import VisualMessage from "../../components/ui/VisualMessage";
import QoloWidget from "../../components/ui/QoloWidget";
import { useFormik } from "formik";
import HttpService from "../../services/HttpService";

export default function AddDebitCard({
  onSave,
  recipients,
  paymentToken,
  processStepUrl,
  recipient,
  token,
  setStep,
}) {
  const currentStep = "3";
  const { getLabel } = useLabels();
  const [selectedRecipient, setSelectedRecipient] = React.useState("");
  const [visualMessage, setVisualMessage] = React.useState();
  const [visibleAddRecipient, setVisibleAddRecipient] = React.useState(true);
  const [widgetVisible, setWidgetVisible] = React.useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");

  const processStep = useMutation(
    (data) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + processStepUrl,
        { ...data, recipient: recipient, token: token },
        { avoidBearer: true }
      );
    },
    {
      onSuccess: (values, data) => {
        onSave({ ...values, ...data });
      },
      onError: (values, data) => {
        onSave(values, ...data);
      },
    }
  );

  const processError = (values) => {
    onSave(values);
  };

  const traceEventMutation = useMutation(
    (data) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + `/trace/external`,
        data
      );
    },
    {
      onSuccess: (value) => {},
      onError: (values) => {},
    }
  );

  const traceEvent = (
    alias,
    instrument_status,
    mask_account_number,
    status_reason
  ) => {
    const transformJsonTrace = () => {
      return {
        email:
          recipients && recipients.length > 0
            ? recipients[0].idCommunication
            : "not_defined@rellevate.com",
        eventName: "card_validate",
        description: instrument_status,
        extraInfo01: "external",
        extraInfo02: mask_account_number,
        extraInfo06: status_reason,
        timestamp: Math.round(Date.now() / 1000),
      };
    };
    const transformedJsonTrace = transformJsonTrace();
    traceEventMutation.mutate(transformedJsonTrace);
  };

  const queryClient = useQueryClient();
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;

  const onWidgetSuccess = () => {
    setWidgetVisible(false);
    setVisibleAddRecipient(false);
    queryClient.invalidateQueries(["recipients", "instant"]);
  };

  const FormAddRecipient = () => {
    return (
      <Dialog
        open={true}
        onClose={(event, reason) => {
          reason !== "backdropClick" && setVisibleAddRecipient(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        p={0}
        m={0}
        disableEscapeKeyDown={true}
        fullScreen={isMobile}
        fullWidth={!isMobile}
        maxWidth="md" // Set a maximum width
        sx={{
          display: isMobile ? "block" : "flex", // Add this line
          justifyContent: "center", // Add this line
          alignItems: "center", // Add this line
          marginLeft: isMobile ? "1rem" : "auto", // Reduce the  margin when isMobile is true
          marginTop: isMobile ? "2rem" : "auto", // Reduce the  margin when isMobile is true
          minWidth: isMobile ? screenWidth * 0.9 : "auto", // Change this line
          width: isMobile ? "85%" : "auto", // Change this line
          height: isMobile ? screenHeight * 0.9 : "auto", // Change this line
        }}
        //PaperProps={{ style: { margin: '0', maxWidth: '100%' } }} // Override Paper styles
      >
        <DialogTitle sx={{ padding: 1, margin: 0 }}>
          {getLabel(
            "sendMoney.repcipient.add.title",
            "Add Debit Card Information: "
          )}
        </DialogTitle>
        <Divider />
        <Box
          noValidate
          autoComplete="off"
          sx={{
            display: "flex", // Add this line
            justifyContent: "center", // Add this line
            alignItems: "center", // Add this line
            width: screenWidth * 0.95, // Change this line
            paddingTop: "1rem",
          }}
        >
          <DialogContent
            sx={{
              margin: 0,
              pt: 1,
              pl: 1,
              pr: 1,
              width: isMobile ? screenWidth * 0.95 : 450,
              height: isMobile ? screenHeight * 0.95 : 660,
            }}
          >
            <Grid
              container
              sx={{
                padding: 0,
                margin: 0,
              }}
            >
              {
                <Grid item xs={12}>
                  <QoloWidget
                    alias="Debit Card"
                    onWidgetSuccess={onWidgetSuccess}
                    paymentToken={paymentToken}
                    traceEvent={traceEvent}
                    addRecipientMutation={processStep}
                    processError={processError}
                    isOwn={false}
                  />
                </Grid>
              }
            </Grid>
            <Box display="flex" justifyContent="center">
              <LoadingButton
                size="small"
                variant="contained"
                color="secondary"
                onClick={() => {
                  if (
                    recipients != null &&
                    recipients !== undefined &&
                    recipients.length > 0
                  ) {
                    setStep(4);
                  } else {
                    setStep(1);
                  }
                }}
              >
                {getLabel(
                  "external-payment." + currentStep + ".btnContinue",
                  "Cancel"
                )}
              </LoadingButton>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    );
  };

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        display="flex"
      >
        <Grid item xs={12} p={0.5} pt={1.5}>
          {visualMessage && (
            <VisualMessage
              title={visualMessage.title}
              message={visualMessage.message}
              severity={visualMessage.severity}
              onClose={visualMessage.onClose}
            />
          )}
          {}
          {FormAddRecipient()}
        </Grid>
      </Grid>
    </>
  );
}
