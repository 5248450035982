const labels = {
  en: {
    "login.title":
      "Sign Up for a Rellevate Account Now! Start your financial wellness journey",
    "login.header": "Digital Banking System",
    "login.username": "Username:",
    "login.username.placeholder": "johndoe@domain.com",
    "login.password": "Password:",
    "login.password.placeholder": " ",
    "login.rememberMe": "Remember me",
    "login.forgotPassword": "Forgot password?",
    "login.bntLabel": "LOG IN",
    "login.signup.bntLabel": "SIGN UP",
    "login.footer":
      "Rellevate facilitates banking services through Sutton Bank",
    "recoveryPassword.step1.username": "Username:",
    "recoveryPassword.step1.code": "Security code:",
    "recoveryPassword.step1.code.placeholder": "Enter the code",
    "recoveryPassword.step1.btnContinue": "VERIFY",
    "recoveryPassword.step1.btnResend": "RESEND CODE",
    "recoveryPassword.step1.header.line1": "Verifying security code",
    "recoveryPassword.step1.header.line2": "You should have received a code.",
    "recoveryPassword.step1.header.line3":
      "Please check you inbox, see you back here shortly.",
    "recoveryPassword.step1.verify.failed.tittle": "Verification failed",
    "recoveryPassword.step1.verify.failed.required":
      "The security code is required",
    "recoveryPassword.step1.verify.failed.message":
      "The security code doesn't match",
    "recoveryPassword.step1.reset.success.tittle": "Reset password success",
    "recoveryPassword.step1.reset.success.message":
      "Your password has been reset successfully.",
    "recoveryPassword.step1.reset.fail.tittle": "Reset password failed",
    "recoveryPassword.step1.reset.fail.message":
      "There was an error, was not able to reset the password.",
    "login.resetPassword.title": "Please reset your password",
    "login.changePassword.newPassword1.label": "New password:",
    "login.changePassword.newPassword2.label": "Repeat the new password:",
    "main.greeting.day": "Good Day",
    "main.greeting.afternoon": "Good Afternoon",
    "main.greeting.evening": "Good Evening",
    "main.availableBalance": "Available Balance",
    "main.pad.availableBalance": "Pay Any-Day Balance",
    "main.card.disclousure": "",
    "main.card.disclousure.rellevate.CORPORATE_EXPENSE":
      "The Rellevate Visa® Pay Card is issued by Sutton Bank, Member FDIC, pursuant to a license from Visa U.S.A. Inc",
    "main.card.disclousure.rellevate.PAYCARD":
      "The Rellevate Visa® Pay Card is issued by Sutton Bank, Member FDIC, pursuant to a license from Visa U.S.A. Inc",
    "main.card.disclousure.rellevate.DIGITAL_FULL":
      "The Rellevate Visa® Debit Card is issued by Sutton Bank, Member FDIC, pursuant to a license from Visa U.S.A. Inc",
    "main.card.disclousure.mastercard.VIRTUALONETIME":
      "The Rellevate Mastercard® Debit Card is issued by Sutton Bank, Member FDIC, pursuant to a license from Mastercard",
    "main.card.disclousure.mastercard.ONETIMELOAD":
      "The Rellevate Mastercard® Pay Card is issued by Sutton Bank, Member FDIC, pursuant to a license from Mastercard.",
    "main.card.disclousure.mastercard.PAYCARD":
      "The Rellevate Mastercard® Pay Card is issued by Sutton Bank, Member FDIC, pursuant to a license from Mastercard.",
    "main.card.disclousure.mastercard.DIGITAL_FULL":
      "The Rellevate Mastercard® Debit Card is issued by Sutton Bank, Member FDIC, pursuant to a license from Mastercard.",

    "main.cardTransaction.title": "Transactions:",
    "cardActivation.btnClose": "Close",
    "cardActivation.p1": "Your card is waiting to be activated",
    "cardActivation.p2":
      "Activate your new Rellevate Paycard after accepting the required documents:",
    "cardActivation.table.lblDocument": "Document",
    "cardActivation.table.lblReview": "Review",
    "cardActivation.table.lblAccept": "Accept",
    "cardActivation.p3":
      "As an added security measure, please also enter the CVV code located at the back of your card and press activate.",
    "cardActivation.btnActivate": "ACTIVATE",
    "cardTransaction.Jan": "Jan",
    "cardTransaction.Feb": "Feb",
    "cardTransaction.Mar": "Mar",
    "cardTransaction.Apr": "Apr",
    "cardTransaction.May": "May",
    "cardTransaction.Jun": "Jun",
    "cardTransaction.Jul": "Jul",
    "cardTransaction.Aug": "Aug",
    "cardTransaction.Sep": "Sep",
    "cardTransaction.Oct": "Oct",
    "cardTransaction.Nov": "Nov",
    "cardTransaction.Dec": "Dec",
    "cardTransaction.loading": "Loading more transactions...",
    "cardTransaction.noMore": "No more transactions",
    "menu.home": "Account",
    "pad.home": "Pay Any-Day",
    "sendMoney.home": "Send Money",
    "billPay.home": "BillPay",
    "profile.home": "Profile",
    "menu.myAccount": "My account",
    "menu.paycardInfo": "Paycard info",
    "menu.changePin": "Change PIN",
    "menu.viewTransactions": "View Transactions",
    "menu.lockCard": "Lock the card",
    "menu.logout": "Logout",
    "login.failed.title": "Login failed.",
    "login.failed.message":
      "Please check your username and password and try again.",
    "cardActivation.failed.title": "Card activation failed.",
    "cardActivation.failed.406.message": "CVV is incorrect.",
    "cardActivation.failed.others.message":
      "There was an error activating your card, please retry.",
    "pad.validation.amount.number": "Amount must be a number",
    "pad.validation.amount.required": "Amount is required",
    "pad.validation.amount.greaterThan": "Amount must be greater than 20",
    "pad.validation.amount.lessThan":
      "Amount cannot be greater than your available PAD balance",
    "pad.totalThisPeriod": "Total This Period",
    "pad.pendingPayback": "Pending Payback",
    "pad.alreadyAdvanced": "Already Advanced",
    "pad.thisPeriod": "This Period:",
    "pad.worked": "Worked",
    "pad.notWorked": "Not Worked",
    "pad.availableToAdvance": "Available to Advance",
    "pad.amount": "Amount to advance",
    "pad.fee": "Transaction fee:",
    "pad.padYourAccountBalance": "Your Account Balance",
    "pad.padBeforeAdvance": "Before Advance",
    "pad.padAfterAdvance": "After Advance",
    "pad.padDisclaimer18old":
      "This product is only available through participating employers and for employees age 18 and over.",
    "pad.padDisclaimer50percent":
      "The Pay Any-Day balance is 50% of gross pay through the last reported time of hours worked from your employer.",
    "config.notifications.SESSION": "Login / Session",
    "config.notifications.EWA": "Earn Wage Access",
    "config.notifications.MONEY_RECEIVED": "Money Received",
    "config.notifications.MONEY_SEND": "Money Sent",
    "config.notifications.BILL_PAY": "Bill Payment",
    "profile.title": "Profile",
    "profile.changePassword": "Change Password",
    "profile.configureNotifications": "Configure Notifications",
    "profile.changeLanguage": "Change Language",
    "profile.privacyPolicy": "Privacy Policy",
    "profile.accountInfo": "Account Information",
    "profile.customerService": "Customer Service",
    "profile.cardAdmin": "Card Admin",
    "profile.customerservice.callus": "Call us at:",
    "profile.customerservice.emailus": "Email us at:",
    "profile.customerservice.emailus.email": "service@rellevate.com",
    "login.changePassword.ok.title": "Password successfully updated",
    "login.changePassword.ok.message":
      "Your password has been successfully updated. Please log in using your new password.",
    "login.changePassword.error.title": "Password update failed",
    "login.changePassword.oldPassword.required": "Old password is required",
    "login.changePassword.newPassword1.required": "New password is required",
    "login.changePassword.newPassword2.match": "Passwords must match",
    "login.changePassword.newPassword2.required": "Repeat the new password",
    "changePassword.title": "Change Password",
    "login.changePassword.title": "Please change your password",
    "login.changePassword.oldPassword.label": "Old password:",
    "changePassword.btnLabel": "Change Password",
    "general.back": "Back",
    "toggle.notification.ok.title": "Notification setup",
    "toggle.notification.ok.message": "The notification was successfully setup",
    "toggle.notification.error.title": "Notification setup error",
    "changeLanguage.error.title": "Language change error",
    "changeLanguage.error.message":
      "There was an error changing the language, please try again",
    "changeLanguage.title": "Select language",
    "changeLanguage.text1":
      "The language selected will be applicable throughout the system from any device",
    "confirmLanguage.title": "Confirm language",
    "confirmLanguage.text1":
      "During login you chose the language different that your profile, please confirm the language",
    "accountInfo.title": "Account Info",
    "changeEmail.failed.title": "Email Change Failed",
    "changeEmail.failed.message": "Email change failed. Please try again.",
    "accountInfo.name": "Name:",
    "accountInfo.email": "Email:",
    "accountInfo.phone": "Phone:",
    "accountInfo.memberId": "Member Id:",
    "accountInfo.company": "Company:",
    "accountInfo.accountNumber": "Account number:",
    "accountInfo.routingNumber": "Routing number:",
    "accountInfo.statements": "Statements",
    "accountInfo.agreements": "Agreements",
    "accountInfo.howtofund": "How to fund",
    "statements.title": "Statements",
    "statements.subtitle":
      "To provide you with accurate financial records, kindly select your preferred monthly statement from the list below",
    "cardAdmin.title": "Card Admin",
    "cardAdmin.lockCard": "Lock Card",
    "cardAdmin.unlockCard": "Unlock Card",
    "cardAdmin.changePin": "Change Pin",
    "cardAdmin.replaceCard": "Replace Card",
    "loginOtp.title": "Verification",
    "loginOtp.message.part1": "Please enter the validation code sent to ",
    "loginOtp.message.part2": " to complete the transaction.",
    "loginOtp.message.notemail": "your inbox",
    "loginOtp.question": "Didn't receive the code?",
    "loginOtp.resend.timeout": "You'll be able to ask for other code in ",
    "termsAndConditions.cardHolderAgreement.title": "Cardholder Agreement",
    "termsAndConditions.digitalProductAgreement.title":
      "Digital Product Agreement",
  },
  es: {
    "login.title":
      "Registrate en Rellevate ahora! Inicia tu bienestar financiero",
    "login.header": "Sistema de Banco Digital",
    "login.username": "Usuario:",
    "login.username.placeholder": "usuario@dominio.com",
    "login.password": "Contraseña:",
    "login.password.placeholder": " ",
    "login.rememberMe": "Recuerdame",
    "login.forgotPassword": "¿Olvidaste el Password?",
    "login.bntLabel": "INICIAR SESION",
    "login.signup.bntLabel": "REGISTRATE",
    "login.footer":
      "Rellevate facilitates banking services through Sutton Bank",
    "recoveryPassword.step1.username": "Usuario:",
    "recoveryPassword.step1.code": "Código de seguridad:",
    "recoveryPassword.step1.code.placeholder": "Ingresa el código",
    "recoveryPassword.step1.btnContinue": "VERIFICAR",
    "recoveryPassword.step1.btnResend": "REENVIAR CODIGO",
    "recoveryPassword.step1.header.line1": "Verificando el código de seguridad",
    "recoveryPassword.step1.header.line2": "Debes haber recibido un código.",
    "recoveryPassword.step1.header.line3":
      "Por favor revisa tu bandeja de entrada, nos vemos pronto.",
    "recoveryPassword.step1.verify.failed.tittle": "Verificación fallida",
    "recoveryPassword.step1.verify.failed.required":
      "El código de seguridad es requerido",
    "recoveryPassword.step1.verify.failed.message":
      "El código de seguridad no coincide",
    "recoveryPassword.step1.reset.success.tittle":
      "Restablecimiento de contraseña exitoso",
    "recoveryPassword.step1.reset.success.message":
      "Tu contraseña ha sido restablecida exitosamente.",
    "recoveryPassword.step1.reset.fail.tittle":
      "Restablecimiento de contraseña fallido",
    "recoveryPassword.step1.reset.fail.message":
      "Existió un error, no se pudo restablecer la contraseña.",
    "login.resetPassword.title": "Por favor restablece tu contraseña",
    "login.changePassword.newPassword1.label": "Nueva contraseña:",
    "login.changePassword.newPassword2.label": "Repite la nueva contraseña:",
    "main.greeting.day": "Buen Día",
    "main.greeting.afternoon": "Buena Tarde",
    "main.greeting.evening": "Buena Noche",
    "main.availableBalance": "Saldo Disponible",
    "main.pad.availableBalance": "Saldo Pay Any-Day",
    "main.card.disclousure":
      "La tarjeta de débito Visa Rellevate (R) es emitida por Sutton Bank, Miembro FDIC, de acuerdo con una licencia de Visa U.S.A. Inc.",
    "main.card.disclousure.rellevate.CORPORATE_EXPENSE":
      "La tarjeta de pago Visa® Rellevate es emitida por Sutton Bank, miembro de FDIC, de acuerdo con una licencia de Visa U.S.A. Inc",
    "main.card.disclousure.rellevate.PAYCARD":
      "La tarjeta de pago Visa® Rellevate es emitida por Sutton Bank, miembro de FDIC, de acuerdo con una licencia de Visa U.S.A. Inc",
    "main.card.disclousure.rellevate.DIGITAL_FULL":
      "La tarjeta de débito Visa® Rellevate es emitida por Sutton Bank, miembro de FDIC, de acuerdo con una licencia de Visa U.S.A. Inc",
    "main.card.disclousure.mastercard.VIRTUALONETIME":
      "La tarjeta de débito Mastercard® Rellevate es emitida por Sutton Bank, miembro de FDIC, de acuerdo con una licencia de Mastercard",
    "main.card.disclousure.mastercard.ONETIMELOAD":
      "La tarjeta de pago Mastercard® Rellevate es emitida por Sutton Bank, miembro de FDIC, de acuerdo con una licencia de Mastercard.",
    "main.card.disclousure.mastercard.DIGITAL_FULL":
      "La tarjeta de débito Mastercard® Rellevate es emitida por Sutton Bank, miembro de FDIC, de acuerdo con una licencia de Mastercard",
    "main.card.disclousure.mastercard.PAYCARD":
      "La tarjeta de pago Mastercard® Rellevate es emitida por Sutton Bank, miembro de FDIC, de acuerdo con una licencia de Mastercard.",
    "main.cardTransaction.title": "Transacciones:",
    "cardActivation.btnClose": "Cerrar",
    "cardActivation.p1": "Tu tarjeta está esperando ser activada",
    "cardActivation.p2":
      "Activa tu nueva tarjeta Rellevate Paycard después de aceptar los documentos requeridos:",
    "cardActivation.table.lblDocument": "Documento",
    "cardActivation.table.lblReview": "Revisar",
    "cardActivation.table.lblAccept": "Aceptar",
    "cardActivation.p3":
      "Como medida de seguridad adicional, ingresa también el código CVV ubicado en la parte posterior de tu tarjeta y presiona activar.",
    "cardActivation.btnActivate": "ACTIVAR",
    "cardTransaction.Jan": "Ene",
    "cardTransaction.Feb": "Feb",
    "cardTransaction.Mar": "Mar",
    "cardTransaction.Apr": "Abr",
    "cardTransaction.May": "May",
    "cardTransaction.Jun": "Jun",
    "cardTransaction.Jul": "Jul",
    "cardTransaction.Aug": "Ago",
    "cardTransaction.Sep": "Sep",
    "cardTransaction.Oct": "Oct",
    "cardTransaction.Nov": "Nov",
    "cardTransaction.Dec": "Dic",
    "cardTransaction.loading": "Cargando más transacciones...",
    "cardTransaction.noMore": "No hay más transacciones",
    "menu.home": "Cuenta",
    "pad.home": "Pay Any-Day",
    "sendMoney.home": "Enviar Dinero",
    "billPay.home": "Pagar Facturas",
    "profile.home": "Perfil",
    "menu.logout": "Cerrar Sesión",
    "login.failed.title": "Inicio de sesión fallido.",
    "login.failed.message":
      "Por favor verifica tu usuario y contraseña e intenta de nuevo.",
    "cardActivation.failed.title": "Fallo en la activación de la tarjeta.",
    "cardActivation.failed.406.message": "El CVV es incorrecto.",
    "cardActivation.failed.others.message":
      "Hubo un error activando tu tarjeta, por favor reintenta.",
    "pad.validation.amount.number": "La cantidad debe ser un número",
    "pad.validation.amount.required": "La cantidad es requerida",
    "pad.validation.amount.greaterThan": "La cantidad debe ser mayor a 20",
    "pad.validation.amount.lessThan":
      "La cantidad no puede ser mayor a tu saldo disponible para adelantos",
    "pad.totalThisPeriod": "Total de este periodo",
    "pad.pendingPayback": "Reembolso Pendiente",
    "pad.alreadyAdvanced": "Ya Adelantado",
    "pad.thisPeriod": "Este Periodo:",
    "pad.worked": "Trabajado",
    "pad.notWorked": "No Trabajado",
    "pad.availableToAdvance": "Disponible para adelanto",
    "pad.amount": "Cantidad a adelantar",
    "pad.fee": "Tarifa de transacción:",
    "pad.padYourAccountBalance": "Tu Saldo de Cuenta",
    "pad.padBeforeAdvance": "Antes del adelanto",
    "pad.padAfterAdvance": "Después del adelanto",
    "pad.padDisclaimer18old":
      "Este producto solo está disponible a través de empleadores participantes y para empleados mayores de 18 años.",
    "pad.padDisclaimer50percent":
      "El saldo Pay Any-Day es el 50% del salario bruto hasta la última hora reportada de horas trabajadas por tu empleador.",
    "config.notifications.SESSION": "Login / Session",
    "config.notifications.EWA": "Earn Wage Access",
    "config.notifications.MONEY_RECEIVED": "Money Received",
    "config.notifications.MONEY_SEND": "Money Sent",
    "config.notifications.BILL_PAY": "Bill Payment",
    "profile.title": "Perfil",
    "profile.changePassword": "Cambiar Contraseña",
    "profile.configureNotifications": "Configurar Notificaciones",
    "profile.changeLanguage": "Cambiar Idioma",
    "profile.privacyPolicy": "Política de Privacidad",
    "profile.accountInfo": "Información de la Cuenta",
    "profile.customerService": "Servicio al Cliente",
    "profile.cardAdmin": "Administración de la Tarjeta",
    "profile.customerservice.callus": "Llámanos al:",
    "profile.customerservice.emailus": "Envíanos un correo a:",
    "profile.customerservice.emailus.email": "service@rellevate.com",
    "login.changePassword.ok.title": "Contraseña actualizada con éxito",
    "login.changePassword.ok.message":
      "Tu contraseña ha sido actualizada con éxito. Por favor inicia sesión usando tu nueva contraseña.",
    "login.changePassword.error.title": "Error al actualizar la contraseña",
    "login.changePassword.oldPassword.required":
      "La contraseña actual es requerida",
    "login.changePassword.newPassword1.required":
      "La nueva contraseña es requerida",
    "login.changePassword.newPassword2.match":
      "Las contraseñas deben coincidir",
    "login.changePassword.newPassword2.required": "Repite la nueva contraseña",
    "changePassword.title": "Cambiar Contraseña",
    "login.changePassword.title": "Por favor cambia tu contraseña",
    "login.changePassword.oldPassword.label": "Contraseña actual:",
    "changePassword.btnLabel": "Cambiar Contraseña",
    "general.back": "Atrás",
    "toggle.notification.ok.title": "Configuración de notificación",
    "toggle.notification.ok.message":
      "La notificación fue configurada con éxito",
    "toggle.notification.error.title":
      "Error en la configuración de notificación",
    "changeLanguage.error.title": "Error al cambiar el idioma",
    "changeLanguage.error.message":
      "Hubo un error cambiando el idioma, por favor intenta de nuevo",
    "changeLanguage.title": "Selecciona el idioma",
    "changeLanguage.text1":
      "El idioma seleccionado será aplicable en todo el sistema desde cualquier dispositivo",
    "confirmLanguage.title": "Confirma el idioma",
    "confirmLanguage.text1":
      "Durante el inicio de sesión elegiste un idioma diferente al de tu perfil, por favor confirma el idioma",
    "accountInfo.title": "Información de la Cuenta",
    "changeEmail.failed.title": "Cambio de Email Fallido",
    "changeEmail.failed.message":
      "El cambio de email falló. Por favor intenta de nuevo.",
    "accountInfo.name": "Nombre:",
    "accountInfo.email": "Email:",
    "accountInfo.phone": "Teléfono:",
    "accountInfo.memberId": "Id de Miembro:",
    "accountInfo.company": "Compañía:",
    "accountInfo.accountNumber": "Número de Cuenta:",
    "accountInfo.routingNumber": "Número de Ruta:",
    "accountInfo.statements": "Declaraciones",
    "accountInfo.agreements": "Acuerdos",
    "accountInfo.howtofund": "Cómo fondear la cuenta",
    "statements.title": "Declaraciones",
    "statements.subtitle":
      "Para proporcionarte registros financieros precisos, selecciona tu declaración mensual preferida de la lista a continuación",
    "cardAdmin.title": "Administración de la Tarjeta",
    "cardAdmin.lockCard": "Bloquear Tarjeta",
    "cardAdmin.unlockCard": "Desbloquear Tarjeta",
    "cardAdmin.changePin": "Cambiar PIN",
    "cardAdmin.replaceCard": "Reemplazar Tarjeta",
    "loginOtp.title": "Verificación",
    "loginOtp.message.part1":
      "Por favor ingrese el código de verificación enviado a ",
    "loginOtp.message.part2": " para completar su transacción.",
    "loginOtp.message.notemail": "su bandeja de entrada",
    "loginOtp.question": "¿Recibiste el código?",
    "loginOtp.resend.timeout": "Podrás enviar otro código en ",
    "termsAndConditions.cardHolderAgreement.title": "Cardholder Agreement",
    "termsAndConditions.digitalProductAgreement.title":
      "Digital Product Agreement",
  },
};

export default labels;
