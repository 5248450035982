import { useQuery } from "@tanstack/react-query";
import HttpService from "../services/HttpService";

async function fetchToken(alias) {
  const response = await HttpService.getAxiosClient().get(
    `${window.API_URL}/payment/token?type=instant&alias=${alias}`
  );
  return response.data;
}

export function usePaymentToken(alias) {
  return useQuery(["PaymentToken", alias], () => fetchToken(alias), {
    enabled: !!alias, // Only fetch if alias is provided
    refetchOnWindowFocus: false,
    staleTime: 10000,
  });
}
