import React, { useState } from "react";
import { Box } from "@mui/system";
import {
  Typography,
  Grid,
  OutlinedInput,
  FormControl,
  InputAdornment,
  IconButton,
} from "@mui/material";
import HttpService from "../../services/HttpService";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useFormik } from "formik";
import * as validatorYup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import VisualMessage from "../ui/VisualMessage";
import LoadingButton from "./LoadingButton";
import useLabels from "../../labels/useLabels";
import CardService from "../../services/CardService";
import FeaturesService from "../../services/config/FeaturesService";

export default function CardActivation() {
  const cardService = CardService();
  const {
    isLoading: featuresServiceIsLoading,
    isError: featuresServiceIsError,
    features,
    error: featuresServiceError,
  } = FeaturesService();

  const { getLabel } = useLabels();

  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = React.useState();

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const queryClient = useQueryClient();

  const ActivationMutation = useMutation(
    (cardCodeData) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + `/card/activate`,
        cardCodeData
      );
    },
    {
      onSuccess: (value) => {
        queryClient.invalidateQueries({ queryKey: ["cardDetails"] });
      },
      onError: (values) => {
        if (values.response.status === "406")
          setErrorMessage({
            title: getLabel(
              "cardActivation.failed.title",
              "Card activation failed."
            ),
            message: getLabel(
              "cardActivation.failed.406.message",
              "CVV is incorrect."
            ),
            onClose: () => setErrorMessage(),
          });
        else
          setErrorMessage({
            title: getLabel(
              "cardActivation.failed.title",
              "Card activation failed."
            ),
            message: getLabel(
              "cardActivation.failed.others.message",
              "There was an error activating your card, please retry."
            ),
            onClose: () => setErrorMessage(),
          });
      },
    }
  );

  const validationSchema = validatorYup.object({
    cardCode:
      cardService.cardDetail.shortLabel !== "VIRTUAL" &&
      !features?.BYPASS_CVV &&
      validatorYup
        .string()
        .matches(/^[0-9]{3}$/, "Must be a three-digit number")
        .required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      cardCode: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      ActivationMutation.mutate({ ...values });
    },
  });

  return (
    <Box sx={{ m: 1.5 }}>
      {errorMessage && (
        <VisualMessage
          title={errorMessage.title}
          message={errorMessage.message}
          onClose={errorMessage.onClose}
        />
      )}
      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
        {getLabel("cardActivation.p1", "Your card is waiting to be activated")}
      </Typography>
      <br />
      <Grid container>
        <Grid item sx={{ width: "100%" }}>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={1}>
              {cardService.cardDetail.shortLabel !== "VIRTUAL" &&
                !features?.BYPASS_CVV && (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        {getLabel(
                          "cardActivation.p3",
                          "As an added security measure, please also enter the CVV code located at the back of your card and press activate."
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth variant="outlined">
                        <OutlinedInput
                          id="cardCode"
                          fullWidth
                          autoComplete="cardCode"
                          sx={{
                            bgcolor: "white",
                          }}
                          type={showPassword ? "text" : "password"}
                          value={formik.values.cardCode}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.cardCode &&
                            Boolean(formik.errors.cardCode)
                          }
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </Grid>
                  </>
                )}
              <Grid item xs={12}>
                <LoadingButton
                  loading={ActivationMutation.isLoading}
                  fullWidth
                  variant="contained"
                  type="submit"
                >
                  {getLabel("cardActivation.btnActivate", "ACTIVATE")}
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
