import { Box, Button, CircularProgress } from "@mui/material";

const LoadingButton = ({ loading, handleButtonClick, children, ...rest }) => {
  return (
    <Box sx={{ position: "relative", padding: 0, margin: 0, minHeight: 24 }}>
      <Button
        variant="text"
        sx={{ display: loading ? "none" : "inline-block" }}
        disabled={loading}
        {...rest}
      >
        {children}
      </Button>
      {loading && (
        <CircularProgress
          size={24}
          color="secondary"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        />
      )}
    </Box>
  );
};

export default LoadingButton;
