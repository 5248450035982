import * as React from "react";
import { Grid, Typography, useMediaQuery, Paper, Divider, CircularProgress, Input, TextField, InputAdornment, Dialog, Button, DialogTitle} from "@mui/material";
import { Box } from "@mui/system";
import Menu from "../../components/ui/Menu";
import useLabels from "../../labels/useLabels";
import { useNavigate } from "react-router-dom";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ProfileOption from "./components/ProfileOption";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import BarChartIcon from '@mui/icons-material/BarChart';
import ArticleIcon from '@mui/icons-material/Article';
import PaidIcon from '@mui/icons-material/Paid';
import CustomerService from "../../services/CustomerService";
import HttpService from "../../services/HttpService";
import LoadingButton from "../../components/ui/LoadingButton";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import VisualMessage from "../../components/ui/VisualMessage";
import OTPInput from "../../components/ui/OTPInput";
import FeaturesService from "../../services/config/FeaturesService";
import MainBackground from "../../components/ui/MainBackground";
import ReactInputMask from "react-input-mask";


export default function AccountInfo() {
  const {getLabel} = useLabels();
  const isMobile = useMediaQuery("(max-width:600px)");
  const customerService = CustomerService();
  const { isLoading: featuresServiceIsLoading, isError: featuresServiceIsError, features, error: featuresServiceError } = FeaturesService();
  const [isDownloading, setIsDownloading] = React.useState(false);
  const [isPhoneEditing, setIsPhoneEditing] = React.useState(false);
  const [isEmailEditing, setIsEmailEditing] = React.useState(false);
  const [newPhone, setNewPhone] = React.useState("");
  const [newEmail, setNewEmail] = React.useState("");
  const [visualMessage, setVisualMessage] = React.useState();
  const [otpFrom, setOtpFrom] = React.useState();
  const navigate = useNavigate();
  const queryClient = useQueryClient();


  const handleHowToFundClick = (event, key) => {
    setIsDownloading(true);

    HttpService.getAxiosClient()
      .get(window.API_URL + `/document/additional_funding/getPDF` , {
        responseType: "json",
      })
      .then((response) => {
        setIsDownloading(false);
        // Decode base64 string, remove the prefix before decoding
        const binaryString = window.atob(response.data);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }
        const blob = new Blob([bytes], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "additional_funding.pdf";
        document.body.appendChild(a); // append the element to the dom
        a.click();
        a.remove(); // afterwards, remove the element

      })
      .catch((error) => {
        setIsDownloading(false);
        console.error(error);
      });
    
    event.preventDefault();
  };

  const changeEmailMutation = useMutation(
    (data) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + `/login/changeEmail`,
        data
      );
    },
    {
      onSuccess: (value) => {
        queryClient.invalidateQueries({ queryKey: ["customerInfo"] });
        setIsEmailEditing(false);
      },
      onError: (values) => {
        setVisualMessage({
            title: getLabel(
              "changeEmail.failed.title",
              "Email Change Failed"
            ),
            message: getLabel(
              "changeEmail.failed.message",
              "Email change failed. Please try again."
            ),
            onClose: () => setVisualMessage(),
          });
      },
    }
  );


  const changePhoneMutation = useMutation(
    (data) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + `/login/changePhone`,
        data
      );
    },
    {
      onSuccess: (value) => {
        queryClient.invalidateQueries({ queryKey: ["customerInfo"] });
        setIsPhoneEditing(false);
      },
      onError: (values) => {
        setVisualMessage({
            title: getLabel(
              "changePhone.failed.title",
              "Phone change Failed"
            ),
            message: getLabel(
              "changePhone.failed.message",
              "Phone change failed. Please try again."
            ),
            onClose: () => setVisualMessage(),
          });
      },
    }
  );

  const handleSaveEmail = () => {
    if (newEmail !== customerService?.customerInfo?.email) 
      setOtpFrom("change-email");
  }

  const handleSavePhone = () => {
    if (newPhone !== customerService?.customerInfo?.phone)
      setOtpFrom("change-phone");
  }


  React.useEffect(() => {
    if (customerService?.customerInfo?.phone) {
      setNewPhone(customerService.customerInfo.phone);
    }
  }, [customerService?.customerInfo?.phone]);

  React.useEffect(() => {
    if (customerService?.customerInfo?.email) {
      setNewEmail(customerService.customerInfo.email);
    }
  }, [customerService?.customerInfo?.email]);

  if (
    customerService.isLoading || isDownloading || featuresServiceIsLoading
  ) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  const handleOnSuccessOTP = (code) => {
    if (otpFrom==="change-email")
      changeEmailMutation.mutate({ newEmail: newEmail, combinationCode: code });
    else if (otpFrom==="change-phone")
      changePhoneMutation.mutate({ newPhone: newPhone, combinationCode: code });
    setOtpFrom();
  }

  const FormOTP = () => (
    <Dialog
      open={!!otpFrom}
      fullScreen
      onClose={(event, reason) => {
        reason !== "backdropClick" && setOtpFrom();
      }}
      disableEscapeKeyDown={true}
      aria-labelledby="otp-dialog-title"
    >
      <DialogTitle id="otp-dialog-title" sx={{ textAlign: "center" }}>
        {getLabel("otp.dialog.title", "One Time Password")}
      </DialogTitle>
      <OTPInput
        recipient={otpFrom==="change-email"?newEmail:newPhone}
        from={otpFrom}
        onClose={() => setOtpFrom()}
        onSuccess={(code) => handleOnSuccessOTP(code)}
        openOTP={true}
      />
    </Dialog>
  );

  function formatPhoneNumber(phoneNumberString) {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }


  return (
    <>
    <FormOTP />
    <div style={{ display: true ? "block" : "none" }}>
      {visualMessage && (
        <VisualMessage
          title={visualMessage.title}
          message={visualMessage.message}
          severity={visualMessage.severity}
          onClose={visualMessage.onClose}
        />
      )}
      <MainBackground
        noValidate
        autoComplete="off"
        isMobile={isMobile}
      >
        <Grid container style={{width:isMobile?"100%":"65%", margin:!isMobile?"3rem auto":0}}  >
          <Grid item xs={12} p={1}>
            <div style={{ display: "flex", justifyContent: "center", }}>
            </div>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <AccountCircleIcon sx={(theme) => ({ color: theme.palette.primary.main , paddingRight: "1rem" })}/>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                {getLabel("accountInfo.title", "Account Info")}
              </Typography>
            </div>
          </Grid>
          <Grid id="accountInfo" item xs={12} p={1}>
            <Paper
                  elevation={2}
                  sx={{
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "flex-start", // Align children to the start
                    flexDirection: "column",
                    cursor: "pointer",
                    textAlign: "left",
                    paddingLeft: "1.5rem",
                    paddingRight: "1.5rem",
                    paddingBottom: "1rem",
                    backgroundColor: "rgba(255, 255, 255, 0.25)",
                  }}
            >
              <div style={{ display: "flex", justifyContent: "left" , marginTop: "1rem"}}>
                <Typography variant="body1" >
                  {getLabel(
                    "accountInfo.name",
                    "Name:"
                  )}
                </Typography>
                <div style={{ display: "flex", justifyContent: "left" }}>
                  <Typography variant="body1" sx={{textAlign: "left",paddingBottom:"0.5rem", paddingInline:"0.5rem", color:"black"}}>
                    {customerService?.customerInfo?.firstName + " " + customerService?.customerInfo?.lastName}
                  </Typography>
                </div>
              </div>

              <Divider orientation="horizontal" flexItem />
              <div style={{ display: "flex", justifyContent: "left", paddingTop:"0.5rem" }}>
                <Typography variant="body1">
                  {getLabel(
                    "accountInfo.email",
                    "Email:"
                  )}
                </Typography>
                {!!!isEmailEditing ? 
                  <>
                    <div style={{ display: "flex", justifyContent: "left" }}>
                      <Typography variant="body1" sx={{textAlign: "left", paddingBottom:"0.5rem", paddingInline:"0.5rem", color:"black"}}>
                        {customerService?.customerInfo?.email }
                      </Typography>
                    </div>
                    <EditIcon  sx={{display: features?.EDIT_PROFILE ? "block" : "none" }} onClick={() => {setIsEmailEditing(true)}} />
                  </>
                :
                  <>
                      <TextField variant="outlined" size="small"  fullWidth={true}
                        sx={{ '& .MuiOutlinedInput-input': {padding: '0px 10px',color:"black"}}}
                        value={newEmail}
                        onChange={(event) => setNewEmail(event.target.value)}/>
                    {!!!changeEmailMutation.isLoading?<SaveIcon onClick={handleSaveEmail} />:<CircularProgress size={"1.5rem"}/>}
                    {!!!changeEmailMutation.isLoading?<CloseIcon onClick={()=>{setIsEmailEditing(false)}} />:<></>}
                  </>
                }
              </div>
              <div style={{ display: features?.HIDE_PHONE ? "none":"block"}}>
                <Divider orientation="horizontal" flexItem />
                <div style={{ diplay: "flex", justifyContent: "left", paddingTop:"0.5rem" }}>
                  <Typography variant="body1">
                    {getLabel(
                      "accountInfo.phone",
                      "Phone:"
                    )}
                  </Typography>
                  {!!!isPhoneEditing ? 
                    <>
                      <div style={{ display: "flex", justifyContent: "left" }}>
                        <Typography variant="body1" sx={{textAlign: "left", paddingBottom:"0.5rem", paddingInline:"0.5rem", color:"black"}}>
                          {formatPhoneNumber(customerService?.customerInfo?.phone) }
                        </Typography>
                      </div>
                      <EditIcon sx={{display: features?.EDIT_PROFILE ? "block" : "none" }} onClick={() => {setIsPhoneEditing(true)}} />
                    </>
                  :
                  <>
                    <ReactInputMask
                        mask="(999) 999-9999"
                        value={newPhone}
                        onChange={(event) => setNewPhone(event.target.value.replace(/\D/g, ''))}>
                        {() => (
                          <TextField variant="outlined" size="small" fullWidth
                          sx={{ '& .MuiOutlinedInput-input': {padding: '0px 10px',color:"black"}}} />
                        )}
                      </ReactInputMask>
                      <SaveIcon onClick={handleSavePhone} />
                      <CloseIcon onClick={()=>{setIsPhoneEditing(false)}} />
                    </>
                  }
                </div>
              </div>

              <Divider orientation="horizontal" flexItem />
              <div style={{ display: "flex", justifyContent: "left", paddingTop:"0.5rem" }}>
                <Typography variant="body1">
                  {getLabel(
                    "accountInfo.memberId",
                    "Member Id:"
                  )}
                </Typography>
                <div style={{ display: "flex", justifyContent: "left" }}>
                  <Typography variant="body1" sx={{textAlign: "left", paddingInline:"0.5rem", color:"black"}}>
                    {customerService?.customerInfo?.memberId }
                  </Typography>
                </div>
              </div>

              <div style={{ display: "flex", justifyContent: "left", paddingTop:"0.5rem" }}>
                <Typography variant="body1">
                  {getLabel(
                    "accountInfo.company",
                    "Company:"
                  )}
                </Typography>
                <div style={{ display: "flex", justifyContent: "left" }}>
                  <Typography variant="body1" sx={{textAlign: "left", paddingBottom:"0.5rem", paddingInline:"0.5rem", color:"black"}}>
                    {customerService?.customerInfo?.companyName }
                  </Typography>
                </div>
              </div>

              {features?.SHOW_ROUTING ? (
              <>
              <Divider orientation="horizontal" flexItem />

              <div style={{ display: "flex", justifyContent: "left", paddingTop:"0.5rem" }}>
                <Typography variant="body1">
                  {getLabel(
                    "accountInfo.accountNumber",
                    "Account number:"
                  )}
                </Typography>
                <div style={{ display: "flex", justifyContent: "left" }}>
                <Typography variant="body1" sx={{textAlign: "left",  paddingInline:"0.5rem", color:"black"}}>
                  {customerService?.customerInfo?.accountNumber }
                </Typography>
              </div>
              </div>

              <div style={{ display: "flex", justifyContent: "left", paddingTop:"0.5rem" }}>
                <Typography variant="body1">
                  {getLabel(
                    "accountInfo.routingNumber",
                    "Routing number:"
                  )}
                </Typography>
                <div style={{ display: "flex", justifyContent: "left" }}>
                  <Typography variant="body1" sx={{textAlign: "left", paddingBottom:"0.5rem", paddingInline:"0.5rem", color:"black"}}>
                    {customerService?.customerInfo?.routingNumber }
                  </Typography>
                </div>
              </div>
              </>

              ):""}


            </Paper>

         </Grid>
          <Grid container pt={2} spacing={2} justifyContent="center">
          {features?.STATEMENTS ? (  
            <ProfileOption 
              isMobile={isMobile} 
              navigateTo="/accountInfo/statements" 
              label="accountInfo.statements" 
              defaultLabel="Statements"
              icon={<BarChartIcon />}  
            /> ) : ""}
            <ProfileOption 
              isMobile={isMobile} 
              navigateTo="/accountInfo/agreements" 
              label="accountInfo.agreements" 
              defaultLabel="Agreements"
              icon={<ArticleIcon />}  
            />
            {features?.SHOW_ROUTING ? (  
            <ProfileOption 
              isMobile={isMobile} 
              callToAction={(event) => handleHowToFundClick(event)}
              label="accountInfo.howtofund" 
              defaultLabel="How to Fund"
              icon={<PaidIcon />}  
            />   ) : ""}  
          </Grid>
          <Grid item xs={12} pt={0}>
            <div
              style={{
                backgroundColor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
            </div>
            <Grid alignContent="center" item>
              <LoadingButton
                sx={{
                  marginTop: "2rem",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                }}
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => {
                  navigate(-1);;
                }}
              >
                {getLabel("general.back", "Go Back")}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>

      </MainBackground>
      <Menu isMobile={isMobile} />
    </div>
    </>
  );
}
