import {
  Grid,
  Typography,
  useMediaQuery,
  SvgIcon,
  FormControl,
  OutlinedInput,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import * as React from "react";
import { Box } from "@mui/system";
import Menu from "../../components/ui/Menu";
import useLabels from "../../labels/useLabels";
import LoadingButton from "../../components/ui/LoadingButton";
import VisualMessage from "../../components/ui/VisualMessage";
import { ReactComponent as billPayIcon } from "../../images/billpay.svg";
import CardService from "../../services/CardService";
import { useFormik } from "formik";
import { ref as validatorYupRef } from "yup";
import * as validatorYup from "yup";
import { CircularProgress } from "@mui/material";
import { addDays, addMonths, format } from "date-fns";
import { useMutation } from "@tanstack/react-query";
import HttpService from "../../services/HttpService";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { throttle } from "lodash";
import Autocomplete from "@mui/material/Autocomplete";
import PayeeService from "../../services/payments/PayeeService";
import AgentService from "../../services/payments/AgentService";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SuccessfullTransaction from "../../components/ui/SuccessfullTransaction";
import MainBackground from "../../components/ui/MainBackground";
import PageMenuOption from "../../components/PageMenuOption";
import { HistoryToggleOff } from "@mui/icons-material";

export default function MainSite() {
  const { getLabel } = useLabels();
  const [agentFilterString, setAgentFilterString] = React.useState("");
  const [agentOptions, setAgentOptions] = React.useState([]);
  const [visualMessage, setVisualMessage] = React.useState();
  const [visibleAddPayee, setVisibleAddPayee] = React.useState(false);
  const [selectedAgent, setSelectedAgent] = React.useState(null);
  const [openDP, setOpenDP] = React.useState(false);
  const [transactionData, setTransactionData] = React.useState(null);
  const navigate = useNavigate();

  const addPayeeMutation = useMutation(
    (data) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + `/payment/payee`,
        data
      );
    },
    {
      onSuccess: (value) => {
        if (value.data && value.data.code === "NoMatch") {
          setVisualMessage({
            title: getLabel(
              "billpay.addAgent.error.title",
              "Error adding payee"
            ),
            message: value.data.message,
            onClose: () => setVisualMessage(),
          });
        } else {
          setVisualMessage({
            title: getLabel(
              "billpay.addAgent.success.title",
              "Payee added successfully"
            ),
            message: getLabel(
              "billpay.addAgent.success.message",
              "The payee was added successfully."
            ),
            onClose: () => {
              setVisualMessage();
              setVisibleAddPayee(false);
            },
          });
        }
      },
      onError: (values) => {
        setVisualMessage({
          title: getLabel("billpay.addAgent.error.title", "Error adding payee"),
          message: getLabel(
            "billpay.addAgent.error..message",
            "There was an error adding the payee. Please try again later."
          ),
          onClose: () => setVisualMessage(),
        });
      },
    }
  );

  const payBillMutation = useMutation(
    (data) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + `/payment/billpay`,
        data
      );
    },
    {
      onSuccess: (value) => {
        if (
          value.status >= 200 &&
          value.status < 300 &&
          value?.data?.transactionId
        ) {
          setTransactionData(value.data);
        } else {
          setVisualMessage({
            title: getLabel("billpay.error.title", "Error during bill pay"),
            message: getLabel(
              "billpay.error.message",
              "There was an error during bill pay. Please try again later."
            ),
            onClose: () => setVisualMessage(),
          });
        }
      },
      onError: (values) => {
        let errorMessage =
          "There was an error during bill pay. Please try again later.";
        if (values?.response?.data?.message) {
          errorMessage = values.response.data.message;
        }
        setVisualMessage({
          title: getLabel("billpay.error.title", "Error during bill pay"),
          message: getLabel("billpay.error.message", errorMessage),
          onClose: () => setVisualMessage(),
        });
      },
    }
  );

  const cardService = CardService();

  const validationPayBillSchema = validatorYup.object({
    payee: validatorYup.object().required(getLabel("", "Payee is required")),
    amount: validatorYup
      .number()
      .required("Amount is required")
      .moreThan(0, "Amount must be greater than zero")
      .typeError("Amount must be a number")
      .max(
        cardService && cardService.cardDetail
          ? cardService.cardDetail.availableBalance
          : 0,
        "Amount cannot exceed Available Balance"
      ),
    when: "",
    dateType: "",
  });

  const validationAddAgentSchema = validatorYup.object({
    agent: validatorYup.string().required(getLabel("", "Agent is required")),
    accountNumber: validatorYup
      .string()
      .required(getLabel("", "Account number is required")),
    confirmAccountNumber: validatorYup
      .string()
      .oneOf(
        [validatorYupRef("accountNumber"), null],
        "Account numbers must match"
      )
      .required(getLabel("", "Confirm the account number is required")),
    zipCode: validatorYup
      .string()
      .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, "Must be a valid zip code")
      .required(getLabel("", "Payee Zip Code is required")),
    alias: validatorYup.string().required(getLabel("", "Alias is required")),
    email: validatorYup.string().email("Must be a valid email"),
  });

  const formikAddAgent = useFormik({
    initialValues: {
      agent: "",
      accountNumber: "",
      confirmAccountNumber: "",
      zipCode: "",
      alias: "",
      email: "",
    },
    validationSchema: validationAddAgentSchema,
    onSubmit: (values) => {
      const transformJson = (original) => {
        return {
          idCommunication: original.email,
          address: {
            country: "",
            city: "",
            postalCode: original.zipCode,
            addressLine1: "",
            addressLine2: null,
            state: "",
          },
          idMoneyMovementItem: original.agent,
          alias: original.alias,
          typeMoneyMovementItem: "PAYEE",
          typeCommunication: original.email ? "EMAIL" : null,
          mask: original.accountNumber,
        };
      };

      const transformedJson = transformJson(values);
      addPayeeMutation.mutate(transformedJson);
    },
  });

  const formikBillPay = useFormik({
    initialValues: {
      payee: "",
      amount: "",
      when: "",
      dateType: "",
    },
    validationSchema: validationPayBillSchema,
    onSubmit: (values) => {
      const transformedJson = {
        idMoneyMovementItem: values.payee.id,
        typeMoneyMovementItem: values.payee.typeMoneyMovementItem,
        amount: values.amount,
        communicationType: values.payee.typeCommunication,
        emailOrPhone: values.payee.idCommunication,
        firstName: values.payee.firstName,
        lastName: values.payee.lastName,
        transferDate: format(values.when, "yyyy/MM/dd"),
        transferFrequency: values.dateType,
        transferType: "billPay",
      };
      payBillMutation.mutate(transformedJson);
    },
  });

  const throttledSetAgentFilterString = React.useMemo(
    () => throttle(setAgentFilterString, 500),
    []
  );

  const payeeService = PayeeService();
  const agentService = AgentService(agentFilterString);

  const isMobile = useMediaQuery("(max-width:600px)");

  React.useEffect(() => {
    setAgentOptions(
      agentService?.agents?.agents.map((item) => ({
        id: item.id,
        label: item.name,
      })) || []
    );
  }, [agentFilterString, agentService?.agents?.agents]);

  if (payeeService.isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  const FormAddPayee = () => {
    return (
      <Dialog
        open={visibleAddPayee}
        onClose={(event, reason) => {
          reason !== "backdropClick" && setVisibleAddPayee(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableEscapeKeyDown={true}
      >
        <DialogTitle id="alert-dialog-title">
          {getLabel("payee.add.title", "Add Payee")}
        </DialogTitle>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={formikAddAgent.handleSubmit}
        >
          <DialogContent>
            <Grid container spacing={2} pt={2}>
              <Grid item xs={12}>
                <Autocomplete
                  autoComplete
                  filterSelectedOptions={false}
                  variant="outlined"
                  disablePortal
                  isOptionEqualToValue={(option, value) => {
                    return option.id === value.id;
                  }}
                  value={selectedAgent}
                  onChange={(event, newValue) => {
                    setSelectedAgent(newValue);
                    formikAddAgent.setFieldValue("agent", newValue?.id);
                  }}
                  getOptionLabel={(option) => option.label}
                  filterOptions={(x) => x}
                  onInputChange={(event, newInputValue) => {
                    throttledSetAgentFilterString(newInputValue);
                  }}
                  loading={agentService?.isLoading}
                  options={agentOptions}
                  sx={{ maxWidth: "80rem", margin: "auto", bgcolor: "white" }}
                  renderInput={(params) => (
                    <TextField
                      label="Agent"
                      id="agent"
                      error={
                        formikAddAgent.touched.agent &&
                        Boolean(formikAddAgent.errors.agent)
                      }
                      helperText={
                        formikAddAgent.touched.agent &&
                        formikAddAgent.errors.agent
                      }
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {agentService?.isLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Accout number"
                  id="accountNumber"
                  type={"text"}
                  value={formikAddAgent.values.accountNumber}
                  onChange={formikAddAgent.handleChange}
                  error={
                    formikAddAgent.touched.accountNumber &&
                    Boolean(formikAddAgent.errors.accountNumber)
                  }
                  helperText={
                    formikAddAgent.touched.accountNumber &&
                    formikAddAgent.errors.accountNumber
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Confirm the account number"
                  id="confirmAccountNumber"
                  value={formikAddAgent.values.confirmAccountNumber}
                  onChange={formikAddAgent.handleChange}
                  error={
                    formikAddAgent.touched.confirmAccountNumber &&
                    Boolean(formikAddAgent.errors.confirmAccountNumber)
                  }
                  helperText={
                    formikAddAgent.touched.confirmAccountNumber &&
                    formikAddAgent.errors.confirmAccountNumber
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Payee Zip Code"
                  id="zipCode"
                  value={formikAddAgent.values.zipCode}
                  onChange={formikAddAgent.handleChange}
                  error={
                    formikAddAgent.touched.zipCode &&
                    Boolean(formikAddAgent.errors.zipCode)
                  }
                  helperText={
                    formikAddAgent.touched.zipCode &&
                    formikAddAgent.errors.zipCode
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Alias"
                  id="alias"
                  value={formikAddAgent.values.alias}
                  onChange={formikAddAgent.handleChange}
                  error={
                    formikAddAgent.touched.alias &&
                    Boolean(formikAddAgent.errors.alias)
                  }
                  helperText={
                    formikAddAgent.touched.alias && formikAddAgent.errors.alias
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Email"
                  id="email"
                  value={formikAddAgent.values.email}
                  onChange={formikAddAgent.handleChange}
                  error={
                    formikAddAgent.touched.email &&
                    Boolean(formikAddAgent.errors.email)
                  }
                  helperText={
                    formikAddAgent.touched.email && formikAddAgent.errors.email
                  }
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              variant="contained"
              size="small"
              type="submit"
              loading={addPayeeMutation.isLoading}
            >
              {getLabel("payee.add.save", "Save Payee")}
            </LoadingButton>
            <LoadingButton
              variant="contained"
              size="small"
              onClick={() => setVisibleAddPayee(false)}
              color="secondary"
            >
              {getLabel("payee.add.close", "Close")}
            </LoadingButton>
          </DialogActions>
        </Box>
      </Dialog>
    );
  };

  const handleSuccessfullTransactionClose = () => {
    formikBillPay.resetForm();
    setTransactionData();
  };

  return (
    <div style={{ display: true ? "block" : "none" }}>
      {transactionData && (
        <SuccessfullTransaction
          message={`Your reference number is ${transactionData.transactionId}`}
          onClose={handleSuccessfullTransactionClose}
        />
      )}
      {visualMessage && (
        <VisualMessage
          title={visualMessage.title}
          message={visualMessage.message}
          severity={visualMessage.severity}
          onClose={visualMessage.onClose}
        />
      )}
      <MainBackground
        component="form"
        noValidate
        autoComplete="off"
        isMobile={isMobile}
      >
        <Grid
          container
          style={{
            width: isMobile ? "100%" : "65%",
            margin: !isMobile ? "3rem auto" : 0,
          }}
        >
          <Grid item xs={12} p={1}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <SvgIcon
                fontSize="medium"
                component={billPayIcon}
                inheritViewBox
              />
              <Typography variant="h6" sx={{ fontWeight: "bold" }} pl={1}>
                Bill Pay
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            pt={4}
            alignContent={"center"}
            textAlign={"center"}
          >
            <Autocomplete
              variant="outlined"
              disablePortal
              id="payee"
              options={payeeService.payees.beneficiaries.map((item) => {
                return {
                  id: item.idMoneyMovementItem,
                  typeMoneyMovementItem: item.typeMoneyMovementItem,
                  idCommunication: item.idCommunication,
                  typeCommunication: item.idCommunication ? "EMAIL" : null,
                  firstName: item.firstName,
                  lastName: item.lastName,
                  label:
                    item.firstName +
                    " (" +
                    item.idMoneyMovementItem +
                    ") - " +
                    item.alias +
                    "",
                };
              })}
              value={formikBillPay.values.payee}
              onChange={(event, newValue) => {
                formikBillPay.setFieldValue("payee", newValue);
              }}
              isOptionEqualToValue={(option, value) => {
                return option.id === value.id;
              }}
              sx={{ maxWidth: "80rem", margin: "auto" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="payee"
                  error={
                    formikBillPay.touched.payee &&
                    Boolean(formikBillPay.errors.payee)
                  }
                  helperText={
                    formikBillPay.touched.payee && formikBillPay.errors.payee
                  }
                  label="Payee"
                  onBlur={formikBillPay.handleBlur}
                  InputProps={{
                    ...params.InputProps,
                    sx: {
                      backgroundColor: "white", // Add this line
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} pt={0}>
            <Grid
              container
              sx={{ maxWidth: "80rem", margin: "auto" }}
              justifyContent={"flex-end"}
            >
              <Grid item>
                <LoadingButton
                  sx={{
                    fontSize: "0.8rem",
                    marginTop: "0.5rem",
                    marginBottom: "1rem",
                  }}
                  color="secondary"
                  variant="contained"
                  onClick={() => {
                    setVisibleAddPayee(true);
                  }}
                >
                  Add Payee
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            pt={1}
            alignContent={"center"}
            textAlign={"center"}
          >
            <FormControl
              fullWidth
              variant="outlined"
              sx={{ maxWidth: "80rem", margin: "auto" }}
            >
              <OutlinedInput
                id="amount"
                fullWidth
                sx={{
                  bgcolor: "white",
                  maxWidth: "80rem",
                  margin: "auto",
                }}
                type={"text"}
                placeholder={getLabel("billpay.amount", "Amount")}
                value={formikBillPay.values.amount}
                onBlur={formikBillPay.handleBlur}
                onChange={formikBillPay.handleChange}
                error={
                  formikBillPay.touched.amount &&
                  Boolean(formikBillPay.errors.amount)
                }
              />
              <FormHelperText sx={{ color: "red" }}>
                {formikBillPay.errors.amount}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} pt={0}>
            <Typography variant="body2" sx={{ textAlign: "center" }}>
              {getLabel(
                "billpay.available",
                "Available Balance: " +
                  new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(
                    cardService && cardService.cardDetail
                      ? cardService.cardDetail.availableBalance
                      : 0
                  )
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} pt={3}>
            <Grid
              container
              textAlign={"center"}
              sx={{ width: isMobile ? "100%" : "90%", margin: "auto" }}
            >
              <Grid item xs={3}>
                <LoadingButton
                  sx={{
                    fontSize: isMobile ? "0.7rem" : "0.8rem",
                  }}
                  variant="contained"
                  color={
                    formikBillPay.values.dateType === "Today"
                      ? "secondary"
                      : "primary"
                  }
                  onClick={() => {
                    formikBillPay.setFieldValue("when", new Date());
                    formikBillPay.setFieldValue("dateType", "Today");
                  }}
                >
                  Today
                </LoadingButton>
              </Grid>
              <Grid item xs={3}>
                <LoadingButton
                  sx={{
                    fontSize: isMobile ? "0.7rem" : "0.8rem",
                  }}
                  variant="contained"
                  color={
                    formikBillPay.values.dateType === "FutureDate"
                      ? "secondary"
                      : "primary"
                  }
                  onClick={() => {
                    formikBillPay.setFieldValue("dateType", "FutureDate");
                    setOpenDP(true);
                  }}
                >
                  Future
                </LoadingButton>
              </Grid>
              <Grid item xs={3}>
                <LoadingButton
                  sx={{
                    fontSize: isMobile ? "0.7rem" : "0.8rem",
                  }}
                  variant="contained"
                  color={
                    formikBillPay.values.dateType === "Monthly"
                      ? "secondary"
                      : "primary"
                  }
                  onClick={() => {
                    formikBillPay.setFieldValue("dateType", "Monthly");
                    setOpenDP(true);
                  }}
                >
                  Monthly
                </LoadingButton>
              </Grid>
              <Grid item xs={3}>
                <LoadingButton
                  sx={{
                    fontSize: isMobile ? "0.7rem" : "0.8rem",
                  }}
                  variant="contained"
                  color={
                    formikBillPay.values.dateType === "Weekly"
                      ? "secondary"
                      : "primary"
                  }
                  onClick={() => {
                    formikBillPay.setFieldValue("dateType", "Weekly");
                    setOpenDP(true);
                  }}
                >
                  Weekly
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} pt={3}>
            <Grid container sx={{ maxWidth: "80rem", margin: "auto" }}>
              <Box>
                {formikBillPay.values.when || openDP ? (
                  <DatePicker
                    open={openDP}
                    id="when"
                    value={formikBillPay.values.when}
                    onAccept={(date) => {
                      formikBillPay.setFieldValue("when", date);
                      setOpenDP(false);
                    }}
                    minDate={new Date()}
                    maxDate={
                      formikBillPay.values.dateType === "Weekly"
                        ? addDays(new Date(), 7)
                        : addMonths(new Date(), 1)
                    }
                    slots={{ openPickerButton: () => "" }}
                    slotProps={{
                      textField: {
                        InputProps: {
                          style: {
                            backgroundColor: "white",
                          },
                        },
                      },
                    }}
                  />
                ) : (
                  ""
                )}
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} pt={3}>
            <LoadingButton
              sx={{
                paddingLeft: "1rem",
                paddingRight: "1rem",
              }}
              variant="contained"
              color="primary"
              fullWidth
              loading={payBillMutation.isLoading}
              onClick={() => {
                formikBillPay.handleSubmit();
              }}
            >
              Pay Bill
            </LoadingButton>
          </Grid>
          <Grid item xs={12} pt={3}>
            <LoadingButton
              sx={{
                paddingLeft: "1rem",
                paddingRight: "1rem",
              }}
              variant="contained"
              color="secondary"
              fullWidth
              loading={payBillMutation.isLoading}
              onClick={() => {
                navigate("/billPayments");
              }}
            >
              View My Payments
            </LoadingButton>
          </Grid>
        </Grid>
      </MainBackground>
      {visibleAddPayee && FormAddPayee()}
      <Menu isMobile={isMobile} />
    </div>
  );
}
