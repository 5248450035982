import * as React from "react";
import Box from "@mui/material/Box";
import { redirect, useParams, useLocation } from "react-router-dom";
import Background from "../components/ui/Background";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  useMediaQuery,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Typography from "@mui/material/Typography";

import { useFormik } from "formik";
import * as validatorYup from "yup";
import { useMutation } from "@tanstack/react-query";
import HttpService from "../services/HttpService";
import UserService from "../services/UserService";
import { useNavigate } from "react-router-dom";
import VisualMessage from "../components/ui/VisualMessage";
import LoadingButton from "../components/ui/LoadingButton";
import useLabels from "../labels/useLabels";
import LanguageIcon from "@mui/icons-material/Language";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage, setLoginLanguage } from "../store/slices/languageSlice";
import { setUser } from "../store/slices/userSlice";
import store from "../store/store";
import OTPInput from "../components/ui/OTPInput";
import { useTheme } from "@mui/material/styles";
import SubDomainService from "../services/config/SubDomainService";
import StepTermsAndConditions from "./onboarding/StepTermsAndConditions";

const validationSchema = validatorYup.object({
  username: validatorYup.string().required(),
  password: validatorYup.string().required(),
});

const vsChangePassword = validatorYup.object({
  oldPassword: validatorYup.string().required(),
  newPassword1: validatorYup.string().required(),
  newPassword2: validatorYup.string().required(),
});

window.setTermsAndConditions = function (value) {
  setTimeout(() => {
    var eventAsJson = JSON.parse(value);
    window.postMessage({ ...eventAsJson, type: "terms-and-conditions" }, "*");
  }, 500);
};

export default function LoginPage() {
  const isMobile = useMediaQuery("(max-width:600px)");
  const { getLabel } = useLabels();
  const [pendingTermsAndConditions, setPendingTermsAndConditions] =
    React.useState();
  const [loaded, setLoaded] = React.useState(false);
  const [changePassword, setChangePassword] = React.useState(false);
  const [viewTermsAndConditions, setViewTermsAndConditions] =
    React.useState(false);
  const [backgroundImage, setBackgroundImage] = React.useState(null);
  const [logo, setLogo] = React.useState(null);
  const [cardDesign, setCardDesign] = React.useState(null);
  const [askOTP, setAskOTP] = React.useState(false);
  const [visualMessage, setVisualMessage] = React.useState();
  const [loginToken, setLoginToken] = React.useState();
  const navigate = useNavigate();
  const langChangeFitTop = useMediaQuery("(min-height:740px)");
  const theme = useTheme(); // Access the theme
  const isXs = useMediaQuery("(max-width:600px)"); // Custom media query string for extra small screens
  const [showImportantDialog, setShowImportantDialog] = React.useState(false);
  const [importantMessageBody, setImportantMessageBody] = React.useState("");
  const [importantMessageTitle, setImportantMessageTitle] = React.useState("");

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isMobileApp = queryParams.has("mobileApp")
    ? queryParams.get("mobileApp") === "true"
    : false;

  const {
    isLoading: configServiceIsLoading,
    isError: configServiceIsError,
    config: configData,
    error: configServiceError,
  } = SubDomainService();

  React.useEffect(() => {
    async function getInitials(subdomain) {
      try {
        const [backgroundImage, logo] = await Promise.all([
          import(`../images/bg_login.${subdomain}.jpg`),
          import(`../images/logo.${subdomain}.svg`),
        ]);
        setBackgroundImage(backgroundImage.default);
        setLogo(logo.default);
      } catch (error) {
        getInitials("rv");
      }
      try {
        const [cardDesign] = await Promise.all([
          import(`../images/cards/card.${subdomain}.svg`),
        ]);
        setCardDesign(cardDesign.default);
      } catch (error) {
        try {
          const [cardDesign] = await Promise.all([
            import(`../images/cards/card.${subdomain}.png`),
          ]);
          setCardDesign(cardDesign.default);
        } catch (ignore) {}
      }
    }
    getInitials(window.subdomain);
    dispatch(setUser(""));
  }, []);

  const LoginMutation = useMutation(
    (loginData) => {
      const requireHash = configData?.["require-activation-hash"] === "true";
      if (requireHash) {
        const urlParams = new URLSearchParams(window.location.search);
        let signOnValue = urlParams.get("sign-on");
        loginData.hash = signOnValue;
      }
      return HttpService.getAxiosClient().post(
        window.API_URL + `/login`,
        loginData,
        { avoidBearer: true }
      );
    },
    {
      onSuccess: (value) => {
        UserService.setTokens(
          value.data.token,
          value.data.refreshToken,
          value.data.idToken
        );

        if (store.getState().language.profile === value.data.lang)
          UserService.updateToken(() => {
            navigate("/home");
          });
        else {
          dispatch(setLanguage(value.data.lang ? value.data.lang : "en"));
          UserService.updateToken(() => {
            navigate("/confirmLanguage");
          });
        }
      },
      onError: (values) => {
        if (values.response.status === 301) {
          const action = values.response.data.action;
          if (action === "REDIRECT_TO") {
            const urlParams = new URLSearchParams(window.location.search);
            let signOnValue = urlParams.get("sign-on");
            const redirectTo = values.response.data.detail;
            if (signOnValue) {
              signOnValue = encodeURIComponent(decodeURIComponent(signOnValue)); // Decode and re-encode the signOnValue
              window.location = `${redirectTo}/?sign-on=${signOnValue}`;
            } else {
              window.location = `${redirectTo}`;
            }
          }
        } else {
          const errorCode = values?.response?.data?.exceptionMessage;

          if (errorCode === "INVALID_HASH") {
            setImportantMessageTitle(
              getLabel("login.error." + errorCode + ".title", "Invalid Hash")
            );
            setImportantMessageBody(
              getLabel("login.error." + errorCode + ".message", "Invalid Hash")
            );
            setShowImportantDialog(true);
          } else {
            switch (values.response.data.error_description) {
              case "Account is not fully set up":
                setChangePassword(true);
                break;
              case "Account is pending to accept terms and conditions":
                setPendingTermsAndConditions(
                  values.response.data.pending_terms_and_conditions
                );
                break;
              default:
                setVisualMessage({
                  title: getLabel("login.failed.title", "Login failed."),
                  message: getLabel(
                    "login.failed.message",
                    "Please check your username and password and try again."
                  ),
                  onClose: () => setVisualMessage(),
                });
                break;
            }
          }
        }
      },
    }
  );

  React.useEffect(() => {
    if (pendingTermsAndConditions) {
      setViewTermsAndConditions(true);
    }
  }, [pendingTermsAndConditions]);

  const changePasswordMutation = useMutation(
    (changePasswordData) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + `/login/changePassword`,
        changePasswordData,
        { avoidBearer: true }
      );
    },
    {
      onSuccess: (value) => {
        setVisualMessage({
          title: getLabel(
            "login.changePassword.ok.title",
            "Password successfully updated"
          ),
          message: getLabel(
            "login.changePassword.ok.message",
            "Your password has been successfully updated. Please log in using your new password."
          ),
          severity: "success",
          onClose: () => setVisualMessage(),
        });
        formik.resetForm();
        formikChangePassword.resetForm();
        setChangePassword(false);
      },
      onError: (values) => {
        setVisualMessage({
          title: getLabel(
            "login.changePassword.error.title",
            "Password update failed"
          ),
          message:
            values.response.status === 401
              ? "The old password is incorrect"
              : values.response?.statusText + " " + values.response?.data,
          severity: "error",
          onClose: () => setVisualMessage(),
        });
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
      token: "",
      rememberMe: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      LoginMutation.mutate({ ...values });
    },
  });

  const formikChangePassword = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword1: "",
      newPassword2: "",
    },
    validationSchema: vsChangePassword,
    onSubmit: (values) => {
      values.username = formik.values.username;
      changePasswordMutation.mutate({ ...values });
    },
  });

  const [showPassword, setShowPassword] = React.useState(false);
  const language = useSelector((state) => state.language.profile);
  const dispatch = useDispatch();
  const { username: urlUsername } = useParams();
  const [usernameFromUrl, setUsernameFromUrl] = React.useState(urlUsername);

  React.useEffect(() => {
    if (usernameFromUrl) {
      let decodedData = window.atob(usernameFromUrl);
      formik.setFieldValue("username", decodedData);
    }
  }, [usernameFromUrl]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const forgotPassword = () => {
    if (formik.values.username === "")
      setVisualMessage({
        title: getLabel("login.forgotPassword.error.title", "Error"),
        message: getLabel(
          "login.forgotPassword.error.message",
          "Please enter your username and try again."
        ),
        severity: "error",
        onClose: () => setVisualMessage(),
      });
    else {
      dispatch(setUser(formik.values.username));
      navigate("/recoveryPassword/step1");
    }
  };

  const changePasswordForm = () => (
    <Grid
      alignItems="center"
      container
      justifyContent="center"
      padding={0}
      sx={{ width: "100%", height: "100%" }}
    >
      <Typography
        variant="h6"
        sx={{
          fontFamily: "Open Sans",
          color: "loginText.main",
        }}
      >
        {getLabel("login.changePassword.title", "Please change your password")}
      </Typography>
      <Grid item sx={{ width: "85%" }}>
        <InputLabel
          htmlFor="oldPassword"
          sx={{
            fontFamily: "Open Sans",
            color: "loginText.main",
          }}
        >
          {getLabel("login.changePassword.oldPassword.label", "Old password:")}
        </InputLabel>
      </Grid>
      <Grid item sx={{ width: "85%" }}>
        <FormControl fullWidth variant="outlined">
          <OutlinedInput
            id="oldPassword"
            fullWidth
            autoComplete="password"
            sx={{
              bgcolor: "white",
            }}
            type={showPassword ? "text" : "password"}
            value={formikChangePassword.values.oldPassword}
            onChange={formikChangePassword.handleChange}
            error={
              formikChangePassword.touched.oldPassword &&
              Boolean(formikChangePassword.errors.oldPassword)
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </Grid>
      <Grid item sx={{ width: "85%" }}>
        <InputLabel
          htmlFor="newPassword1"
          sx={{
            fontFamily: "Open Sans",
            color: "loginText.main",
          }}
        >
          {getLabel("login.changePassword.newPassword1.label", "New password:")}
        </InputLabel>
      </Grid>
      <Grid item sx={{ width: "85%" }}>
        <FormControl fullWidth variant="outlined">
          <OutlinedInput
            id="newPassword1"
            fullWidth
            autoComplete="password"
            sx={{
              bgcolor: "white",
            }}
            type={showPassword ? "text" : "password"}
            value={formikChangePassword.values.newPassword1}
            onChange={formikChangePassword.handleChange}
            error={
              formikChangePassword.touched.newPassword1 &&
              Boolean(formikChangePassword.errors.newPassword1)
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </Grid>
      <Grid item sx={{ width: "85%" }}>
        <InputLabel
          htmlFor="newPassword2"
          sx={{
            fontFamily: "Open Sans",
            color: "loginText.main",
          }}
        >
          {getLabel(
            "login.changePassword.newPassword2.label",
            "Repeat the new password:"
          )}
        </InputLabel>
      </Grid>
      <Grid item sx={{ width: "85%" }}>
        <FormControl fullWidth variant="outlined">
          <OutlinedInput
            id="newPassword2"
            fullWidth
            autoComplete="password"
            sx={{
              bgcolor: "white",
            }}
            type={showPassword ? "text" : "password"}
            value={formikChangePassword.values.newPassword2}
            onChange={formikChangePassword.handleChange}
            error={
              formikChangePassword.touched.newPassword2 &&
              Boolean(formikChangePassword.errors.newPassword2)
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </Grid>

      <Grid item sx={{ width: "85%" }}>
        <LoadingButton
          type="submit"
          variant="contained"
          loading={changePasswordMutation.isLoading}
          sx={{ width: "100%", mb: "10px" }}
        >
          {getLabel("changePassword.btnLabel", "Change Password")}
        </LoadingButton>
      </Grid>
    </Grid>
  );

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return email !== "" && emailRegex.test(email);
  };

  const handleLoginClick = () => {
    if (
      (theme.notEmailForLogin === "true" &&
        formik.values.username.length > 3) ||
      validateEmail(formik.values.username)
    ) {
      if (theme.askOTP !== "false") {
        setAskOTP(true);
      } else {
        formik.handleSubmit();
      }
    } else {
      setVisualMessage({
        title: getLabel("login.failed.title", "Login failed."),
        message: getLabel(
          "login.failed.message",
          "Please check your username and password and try again."
        ),
        onClose: () => setVisualMessage(),
      });
    }
  };

  const loginForm = () => (
    <Grid
      alignItems="center"
      container
      justifyContent="center"
      padding={0}
      pt={2}
      sx={{ width: "100%", height: "100%" }}
    >
      {theme.showLoginTitle === "true" ? (
        <Grid item sx={{ width: "85%" }}>
          <Typography
            variant="h6"
            sx={{
              fontFamily: "Open Sans, sans-serif",
              color: "loginText.main",
              fontWeight: "600",
            }}
          >
            {getLabel("login.title", "Sign In")}
          </Typography>
        </Grid>
      ) : (
        <> </>
      )}
      <Grid item sx={{ width: "85%" }}>
        <InputLabel
          htmlFor="username"
          sx={{
            fontFamily: "Open Sans",
            color: "loginText.main",
          }}
        >
          {getLabel("login.username")}
        </InputLabel>
      </Grid>
      <Grid item sx={{ width: "85%" }}>
        <FormControl fullWidth variant="outlined">
          <OutlinedInput
            fullWidth
            id="username"
            sx={{
              bgcolor: theme.custom?.loginBox?.fieldBackground || "white",
            }}
            placeholder={getLabel("login.username.placeholder")}
            value={formik.values.username}
            onChange={formik.handleChange}
            error={formik.touched.username && Boolean(formik.errors.username)}
          />
        </FormControl>
      </Grid>
      <Grid item sx={{ width: "85%" }}>
        <InputLabel
          htmlFor="password"
          sx={{
            fontFamily: "Open Sans",
            color: "loginText.main",
          }}
        >
          {getLabel("login.password")}
        </InputLabel>
      </Grid>
      <Grid item sx={{ width: "85%" }}>
        <FormControl fullWidth variant="outlined">
          <OutlinedInput
            id="password"
            fullWidth
            autoComplete="password"
            sx={{
              bgcolor: theme.custom?.loginBox?.fieldBackground || "white",
            }}
            placeholder={getLabel("login.password.placeholder")}
            type={showPassword ? "text" : "password"}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </Grid>
      <Grid
        alignItems="center"
        container
        padding={0}
        pt={2}
        flexDirection={"row"}
        justifyContent="space-between"
        item
        sx={{ width: "85%" }}
      >
        {theme.rememberMeEnabled === "false" ? (
          <></>
        ) : (
          <Grid item>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox size="large" sx={{ color: "primary" }} />}
                label={getLabel("login.rememberMe")}
                sx={{ color: "loginText.main" }}
              />
            </FormGroup>
          </Grid>
        )}
        {theme.forgotPasswordEnabled === "false" ? (
          <></>
        ) : (
          <Grid item textAlign="right" alignContent="right">
            <Button
              size="small"
              sx={{ mr: 2, color: "loginText.main" }}
              width="100%"
              onClick={() => forgotPassword()}
            >
              {getLabel("login.forgotPassword")}
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid container justifyContent="center" sx={{ width: "85%" }}>
        <Grid item xs={12}>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={LoginMutation.isLoading}
            sx={{ width: "100%", mb: "10px", textTransform: "none" }}
          >
            {getLabel("login.bntLabel")}
          </LoadingButton>
        </Grid>
        {theme.signUpEnabled === "false" ? (
          <></>
        ) : (
          <Grid item xs={12}>
            <LoadingButton
              variant="contained"
              color="secondary"
              sx={{ width: "100%", mb: "25px" }}
              onClick={() => navigate("/onboarding")}
            >
              {getLabel("login.signup.bntLabel", "Sign Up")}
            </LoadingButton>
          </Grid>
        )}
      </Grid>
    </Grid>
  );

  React.useEffect(() => {
    document.title = getLabel(
      "login.title",
      "Sign Up for a Rellevate Account Now! Start your financial wellness journey"
    );
  }, [getLabel("login.title")]);

  const handleOnSuccessOTP = (code) => {
    setAskOTP(false);
    formik.setFieldValue("token", code);
    setLoginToken(code);
  };

  React.useEffect(() => {
    if (loginToken) {
      formik.handleSubmit();
      setLoginToken();
    }
  }, [loginToken]);

  React.useEffect(() => {
    window.addEventListener("message", (event) => {
      if (event.data["type"] === "terms-and-conditions") {
        formik.values.username = event.data["username"];
        setPendingTermsAndConditions({
          terms_and_conditons_token:
            event.data["pending_terms_and_conditions"][
              "terms_and_conditons_token"
            ],
          employer_id:
            event.data["pending_terms_and_conditions"]["employer_id"],
          complete_name:
            event.data["pending_terms_and_conditions"]["complete_name"],
          list: event.data["pending_terms_and_conditions"]["list"],
          cardType: event.data["pending_terms_and_conditions"]["cardType"],
        });
      }
    });
    return () => {
      window.removeEventListener("message", () => {});
    };
  }, []);

  const FormOTP = (from) => (
    <Dialog
      open={askOTP}
      fullScreen
      onClose={(event, reason) => {
        reason !== "backdropClick" && setAskOTP(false);
      }}
      disableEscapeKeyDown={true}
      aria-labelledby="otp-dialog-title" // Add aria-labelledby
    >
      <DialogTitle id="otp-dialog-title" sx={{ textAlign: "center" }}>
        {getLabel("otp.dialog.title", "One Time Password")}
      </DialogTitle>
      <OTPInput
        recipient={formik.values.username}
        from={"login"}
        onClose={() => setAskOTP(false)}
        onSuccess={(code) => handleOnSuccessOTP(code)}
        openOTP={true}
      />
    </Dialog>
  );

  if (configServiceIsLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  if (configData?.["verify-redirection"] === "true") {
    const urlParams = new URLSearchParams(window.location.search);
    let signOnValue = urlParams.get("sign-on");
    const redirect = urlParams.get("redirect");
    const redirectTo = configData?.["activation-url"];
    const automaticRedirection = configData?.["automatic-redirection"];
    if (
      (redirect === "true" || automaticRedirection === "true") &&
      redirectTo
    ) {
      if (signOnValue) {
        signOnValue = encodeURIComponent(decodeURIComponent(signOnValue)); // Decode and re-encode the signOnValue
        window.location = `${redirectTo}/?sign-on=${signOnValue}`;
      } else {
        window.location = `${redirectTo}`;
      }
    }
  }

  const sendMessageToAppWebView = (tokenInfo) => {
    const message = { type: "confirmedTerms", payload: tokenInfo };
    if (window.messageHandler) {
      window.messageHandler.postMessage(JSON.stringify(message));
    } else {
      console.error("messageHandler channel not available");
    }
    //window.postMessage(JSON.stringify(message), "*"); // Use "*" for targetOrigin if you don't know the exact origin of the Flutter app, but it's better to specify it if possible for security reasons.
  };

  const FormTermsAndConditions = () => (
    <Dialog
      open={viewTermsAndConditions}
      onClose={(event, reason) => {
        reason !== "backdropClick" && setViewTermsAndConditions(false);
      }}
      disableEscapeKeyDown={true}

      PaperProps={{
        sx: { 
          borderRadius: "10px",
          margin: isMobile ? "2%" : "auto",
          padding: isMobile ? "0" : "auto",
          width: isMobile ? "96%" : "60%",
          overflow: "hidden",
          boxShadow: "0px 0px 10px 0px #000000",
        }
      }}

    >
      <DialogTitle sx={{ backgroundColor: "primary.main", color: "white", padding: "10px" }}>
        <Typography variant="subtitle" fontWeight={600}>
          {getLabel(
            "termsAndConditions.title",
            "Terms and Conditions Acceptance"
          )}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ mt: "20px" }}>
        <StepTermsAndConditions
          email={formik.values.username}
          token={pendingTermsAndConditions?.terms_and_conditons_token}
          employerId={pendingTermsAndConditions?.employer_id}
          completeName={pendingTermsAndConditions?.complete_name}
          documentList={pendingTermsAndConditions?.list}
          bagContent={{ cardType: pendingTermsAndConditions?.cardType }}
          comesFrom="terms-and-conditions"
          onCheck={(value, combinationCode) => {
            if (value === "7") {
              setViewTermsAndConditions(false);
              formik.setFieldValue("token", combinationCode);
              if (isMobileApp) {
                sendMessageToAppWebView({
                  username: formik.values.username,
                  token: combinationCode,
                });
              } else {
                setLoginToken(combinationCode);
              }
            }
          }}
        />
      </DialogContent>
    </Dialog>
  );

  return (
    <div style={{ display: loaded ? "block" : "none" }}>
      <div id="blabla" />
      <FormOTP />
      <FormTermsAndConditions />
      <Background image={backgroundImage} onLoad={() => setLoaded(true)}>
        {visualMessage && (
          <VisualMessage
            title={visualMessage.title}
            message={visualMessage.message}
            severity={visualMessage.severity}
            onClose={visualMessage.onClose}
          />
        )}
        <main>
          {showImportantDialog && (
            <Dialog
              open={true}
              onClose={console.log("Close")}
              disableEscapeKeyDown={true}
            >
              <DialogTitle>
                <Typography variant="subtitle" fontWeight={600}>
                  {importantMessageTitle}
                </Typography>
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={1} justifyContent="center">
                  <Grid item padding={2}>
                    <Typography textAlign="justify">
                      {importantMessageBody}
                    </Typography>
                  </Grid>
                  <Grid item width="100%" padding={2}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        window.location = "/";
                      }}
                    >
                      {getLabel("cardActivation.btnOk", "Ok")}
                    </Button>
                  </Grid>
                </Grid>
              </DialogContent>
            </Dialog>
          )}
          <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={
              !changePassword
                ? (event) => {
                    event.preventDefault();
                    handleLoginClick();
                  }
                : formikChangePassword.handleSubmit
            }
          >
            <Grid container alignItems="center" sx={{ height: "100vh" }}>
              <Grid
                container
                alignItems="center"
                sx={{ height: { xs: "none", sm: "600px" } }}
              >
                <Grid
                  item
                  xs={12}
                  sx={{
                    textAlign: {
                      xs: theme.custom?.logo?.align?.xs || "center",
                      sm: theme.custom?.logo?.align?.sm || "center",
                    },
                    marginLeft: {
                      xs: theme.custom?.logo?.marginLeft?.xs || "0",
                      sm: theme.custom?.logo?.marginLeft?.sm || "0",
                    },
                  }}
                >
                  <img
                    src={logo}
                    alt="Company Logo"
                    style={{
                      width: isXs
                        ? theme.custom?.logo?.width?.xs || "220px"
                        : theme.custom?.logo?.width?.sm || "265px",
                    }}
                  />
                </Grid>
                {theme.showloginHeader === "false" ? (
                  <></>
                ) : (
                  <Grid item xs={12} sx={{ textAlign: "center", mb: 8 }}>
                    <Typography
                      color="primary"
                      variant="h1"
                      sx={{ fontSize: "24px" }}
                    >
                      {getLabel("login.header")}
                    </Typography>
                  </Grid>
                )}
                <Grid
                  container
                  justifyContent="center"
                  sx={{
                    marginLeft: {
                      xs: theme.custom?.loginBox?.marginLeft?.xs || "0",
                      sm: theme.custom?.loginBox?.marginLeft?.sm || "0",
                    },
                    display: "flex", // Enable flexbox layout
                    flexDirection: { xs: "column", sm: "row" }, // Column layout on small screens, row layout on larger screens
                    alignItems: "center", // Center items vertically
                    justifyContent: {
                      xs:
                        theme.custom?.loginBox?.justifyContent?.xs || "center",
                      sm:
                        theme.custom?.loginBox?.justifyContent?.sm || "center",
                    },
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: {
                        xs: theme.custom?.loginBox?.borderRadius?.xs || "none",
                        sm: "10px",
                      },
                      bgcolor: {
                        xs: theme.custom?.loginBox?.bgColor?.xs || "none",
                        sm:
                          theme.custom?.loginBox?.bgColor?.sm ||
                          "hsla(210,3%,75%,.4)",
                      },
                      width: {
                        xs: theme.custom?.loginBox?.width?.xs || "100%",
                        sm: theme.custom?.loginBox?.width?.sm || "80%",
                      },
                      height: "25rem",
                    }}
                  >
                    {!changePassword ? loginForm() : changePasswordForm()}
                  </Box>
                  {theme.showCardDesign === "true" ? (
                    <Box
                      sx={{
                        position: "relative",

                        display: { xs: "none", sm: "flex" }, // Enable flexbox layout
                        flexDirection: { xs: "column", sm: "row" }, // Column layout on small screens, row layout on larger screens

                        alignItems: "flex-start", // Align items at the top
                        justifyContent: "center", // Center items horizontally

                        marginLeft: {
                          xs: theme.custom?.cardDesign?.marginLeft?.xs || "0",
                          sm: theme.custom?.cardDesign?.marginLeft?.sm || "0",
                        },
                        height: "25rem",
                      }}
                    >
                      <img
                        src={cardDesign}
                        alt="Card Design"
                        style={{
                          width: "350px",
                          //position: "absolute",
                          bottom: "10px",
                          right: "10px",
                        }}
                      />
                    </Box>
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="center">
                    <Typography
                      variant="inherit"
                      maxWidth={"85%"}
                      sx={{
                        marginTop: "2rem",
                        fontFamily: "Open Sans",
                        fontSize: theme.custom?.loginFooter?.size || "1rem",
                        backgroundColor: "loginText.back",
                        color: "loginText.main",
                        opacity: theme.custom?.loginFooter?.opacity || 1.0,
                        textAlign:
                          theme.custom?.loginFooter?.textAlign || "center",
                      }}
                    >
                      {getLabel("login.footer")}
                    </Typography>
                  </Grid>
                  {theme.showLoginFooterLine2 === "true" ? (
                    <Grid container justifyContent="center">
                      <Typography
                        variant="inherit"
                        maxWidth={"85%"}
                        sx={{
                          marginTop: "2rem",
                          fontFamily: "Open Sans",
                          fontSize: theme.custom?.loginFooter?.size || "1rem",
                          backgroundColor: "loginText.back",
                          color: "loginText.main",
                          opacity: theme.custom?.loginFooter?.opacity || 1.0,
                          textAlign:
                            theme.custom?.loginFooter?.textAlign || "center",
                        }}
                      >
                        {getLabel("login.footer.line2")}
                      </Typography>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </main>
      </Background>
      <footer>
        <Box
          sx={{
            position: "fixed",
            bottom: langChangeFitTop ? "0" : "auto",
            top: langChangeFitTop ? "auto" : "0",
            left: 0,
            width: "100%",
            backgroundColor: "rgba(240, 240, 240, 0.5)",
            padding: "5px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {theme.showVersion === "false" ? (
            <></>
          ) : (
            <Typography variant="caption" sx={{ color: "loginText.main" }}>
              {`version: ${String(new Date().getMonth() + 1).padStart(
                2,
                "0"
              )}${String(new Date().getDate()).padStart(2, "0")}${
                window.BUILD_NUMBER
              }`}
            </Typography>
          )}
          {theme.showLanguage === "false" ? (
            <></>
          ) : (
            <Button
              size="small"
              sx={{ mr: 2, color: "loginText.main" }}
              onClick={() => {
                dispatch(setLoginLanguage(language === "en" ? "es" : "en"));
              }}
            >
              <LanguageIcon sx={{ mr: 1 }} />
              {language === "en" ? "Español" : "English"}
            </Button>
          )}
        </Box>
      </footer>
    </div>
  );
}
