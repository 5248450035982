import * as React from "react";
import PaymentTokenService from "../../services/PaymentTokenService";
import VisualMessage from "./VisualMessage";
import useLabels from "../../labels/useLabels";
import { useMediaQuery } from "@mui/material";
export default function QoloWidget({
  alias,
  onWidgetSuccess,
  paymentToken,
  addRecipientMutation,
  traceEvent,
  isOwn,
  processError,
}) {
  const { getLabel } = useLabels();
  const isMobile = useMediaQuery("(max-width:600px)");

  const [visualMessage, setVisualMessage] = React.useState();
  const [scriptLoaded, setScriptLoaded] = React.useState(false);

  const loadScriptIntoIframe = (iframe, urlScript, callback) => {
    if (!scriptLoaded) {
      const iframeDocument = iframe.contentDocument;
      if (!iframeDocument) {
        console.error("iframe.contentDocument is null");
        return;
      }
      if (iframeDocument) {
        // Set the height and width for the iframe's contentDocument
        iframeDocument.documentElement.style.height = "700px"; // Set the <html> height
        iframeDocument.documentElement.style.width = "500px"; // Set the <html> width

        iframeDocument.body.style.height = "100%"; // Set the <body> height
        iframeDocument.body.style.width = "100%"; // Set the <body> width
        iframeDocument.body.style.margin = "0"; // Remove default margins
        iframeDocument.body.style.padding = "0"; // Remove default padding
      }

      const existingScript =
        iframe.contentDocument.getElementById("widget_script");
      if (existingScript) {
        console.log("Script already exists in iframe");
        existingScript.remove(); // Remove the existing script
      }

      const script = document.createElement("script");
      script.src = urlScript;
      script.id = "widget_script";
      iframeDocument.body.appendChild(script);
      script.onload = () => {
        console.log("Script loaded successfully");
        setScriptLoaded(true); // Set scriptLoaded to true after loading the script
        callback(); // Initialize the widget after the script is loaded
      };

      script.onerror = () => {
        console.error("Failed to load the widget script");
      };
    }
  };

  const initializeWidget = (iframe, alias, paymentToken) => {
    const pciWidget = iframe.contentWindow?.pciWidget;
    if (!pciWidget) {
      console.error("pciWidget is not available");
      return;
    }

    const iframeDocument = iframe.contentDocument;
    if (!iframeDocument) {
      console.error("iframe.contentDocument is null");
      return;
    }

    // Ensure the required DOM elements exist in the iframe
    let widgetContainer = iframeDocument.getElementById("widget_container");
    if (!widgetContainer) {
      widgetContainer = iframeDocument.createElement("div");
      widgetContainer.id = "widget_container";
      iframeDocument.body.appendChild(widgetContainer);
    }

    setTimeout(() => {
      const widget = {
        onSuccess: (details) => {
          if (details.result) {
            const {
              outside_instrument_guid,
              person_guid,
              instrument_type,
              mask_account_number,
              instrument_status,
              status_reason,
            } = details.result;

            if (alias)
              traceEvent(
                alias,
                instrument_status,
                mask_account_number,
                status_reason
              );
            else
              traceEvent(
                "",
                instrument_status,
                mask_account_number,
                status_reason
              );
            if (
              instrument_status !== "ACTIVE" &&
              instrument_status !== "UNPROVED"
            ) {
              /*TODO comment UNPROVED o production */

              setVisualMessage({
                title: getLabel(
                  "sendMoney.addAgent.error.title",
                  "Unable to add the debit card"
                ),
                message:
                  "The account could not be verified, please check the accuracy of the information.",
                onClose: () => {
                  setVisualMessage();
                  onWidgetSuccess();
                  if (processError !== undefined && processError !== null)
                    processError({
                      alias,
                      instrument_status,
                      mask_account_number,
                      status_reason,
                    });
                },
              });

              return;
            }

            const transformJson = () => {
              return {
                alias: alias,
                mask: mask_account_number,
                idBeneficiary: person_guid,
                idMoneyMovementItem: outside_instrument_guid,
                typeMoneyMovementItem: instrument_type,
                isOwn: isOwn,
                instrumentStatus: instrument_status,
              };
            };
            const transformedJson = transformJson();
            addRecipientMutation.mutate(transformedJson);
          }
        },
        onFailure: (details) => {
          setVisualMessage({
            title: getLabel(
              "sendMoney.addAgent.error.title",
              "Unable to add the debit card"
            ),
            message:
              "Submission failed. Please review your information and try again.",
            onClose: () => {
              setVisualMessage();
              if (processError !== undefined && processError !== null)
                processError({
                  alias,
                  instrument_status: "NOT_PROCESS",
                  mask_account_number: "",
                  status_reason: "999999",
                });
            },
          });
        },
        frame: {
          container_id: "widget_container",
          iframe_class: "widget-iframe",
          filter:
            "invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%)",
        },
        target_origin: window.ism_target_origin,
        config: {
          token: paymentToken,
          payment: "external_payment_card",
          content: {
            external_payment_card_head: `${alias} Card Details`,
            external_payment_card__name_on_card: "Name on Card",
            external_payment_card__outside_card_number: "Card Number",
            external_payment_card__expiration_date: "Expiration Date",
            external_payment_card__verification_code: "Verification Code (CVV)",
            submit_text: "Register Debit Card",
          },
          css: {
            container: {
              border: 0,
              margin: 0,
              padding: 0,
              overflowX: "hidden",
            },
            vc_bg: {
              background: "linear-gradient(green, yellow, black)",
            },
            vc_logo: {
              "background-image": "url(cards/qolo.png)",
              top: "5%",
              right: "4%",
            },
            external_payment_card__name_on_card: {
              "font-weight": "600",
              "font-size": ".875em",
              color: "#D0D0D0",
            },
            row: {
              "flex-direction": "column",
            },
            text_right: {
              "text-align": "center",
            },
            name_on_card: {
              width: "100%",
              height: "auto",
              padding: "13px 1rem",
              color: "#0E6D6E",
              "font-size": "1em",
              "line-height": "1.4",
              "box-shadow": "none",
            },
            external_payment_card__outside_card_number: {
              "font-weight": "600",
              "font-size": ".875em",
              color: "#0E6D6E",
              display: "block",
              // "font-size": "12px",
            },
            outside_card_number: {
              width: "100%",
              height: "auto",
              padding: "13px 1rem",
              color: "#222222",
              "font-size": "1em",
              "line-height": "1.4",
              "box-shadow": "none",
            },
            external_payment_card__exp_month__exp_year: {
              "font-weight": "600",
              "font-size": ".875em",
              color: "#0E6D6E",
              // "font-size": "12px",
            },
            exp_month: {
              width: "100%",
              height: "auto",
              padding: "13px 1rem",
              color: "#222222",
              "font-size": "1em",
              "line-height": "1.4",
              "box-shadow": "none",
            },
            exp_year: {
              width: "100%",
              height: "auto",
              padding: "13px 1rem",
              color: "#222222",
              "font-size": "1em",
              "line-height": "1.4",
              "box-shadow": "none",
              "margin-top": "0.5rem",
            },
            external_payment_card__verification_code: {
              "font-weight": "600",
              "font-size": ".875em",
              color: "#0E6D6E",
              // "font-size": "12px",
            },
            verification_code: {
              width: "100%",
              height: "auto",
              padding: "13px 1rem",
              color: "#222222",
              "font-size": "1em",
              "line-height": "1.4",
              "box-shadow": "none",
            },
            submit_button: {
              padding: "0.5em",
              "border-radius": "2px",
              "font-size": "14px",
              "font-weight": "bold",
              "text-transform": "capitalize",
              "background-color": "white",
              "border-color": "#0E6D6E",
              color: "#0E6D6E",
              cursor: "pointer",
              "box-shadow": "0px 0px 3px 0px #0e6d6e",
            },
            external_payment_card_head: {
              "font-weight": "600",
              "font-size": "0.5em",
              color: "#0E6D6E",
              "font-family": "'Raleway', sans-serif",
              "text-transform": "uppercase",
            },
            h1: {
              display: "none",
            },
            "col-5": {
              "max-width": "100%",
            },
            "col-4": {
              "max-width": "fit-content",
              "margin-right": "1px !important",
            },
            "col-12": {
              display: "none",
            },
            status: {
              display: "none",
            },
          },
        },
      };

      pciWidget(widget);

      const iframe = document.getElementById("widget_iframe");

      const widget_container =
        iframe.contentDocument.getElementById("widget_container");
      if (widget_container) {
        widget_container.style.width = isMobile ? "310" : "420";
        widget_container.style.height = "580px";
      }

      const widget_frame =
        iframe.contentDocument.getElementById("widget_frame");
      if (widget_frame) {
        widget_frame.style.width = widget_container.style.width;
        widget_frame.style.height = widget_container.style.height;
      }
    }, 0);
  };

  const callWidget = (alias, paymentToken) => {
    const iframe = document.getElementById("widget_iframe");
    if (!iframe) {
      console.error("Iframe not found");
      return;
    }
    iframe.src = "about:blank";

    iframe.addEventListener("load", () => {
      const urlScript = window.ism_widget_url;
      if (!urlScript) {
        console.error("urlScript is undefined or null");
        return;
      }

      if (!scriptLoaded) {
        loadScriptIntoIframe(iframe, urlScript, () => {
          initializeWidget(iframe, alias, paymentToken);
        });
      }
    });

    // Ensure the iframe has a valid src
    if (!iframe.src) {
      iframe.src = "about:blank";
    }
  };

  React.useEffect(() => {
    if (paymentToken) {
      callWidget(alias, paymentToken);
    } else {
      console.log("No payment token available");
    }
  }, [paymentToken]);

  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;

  return (
    <>
      {visualMessage && (
        <VisualMessage
          title={visualMessage.title}
          message={visualMessage.message}
          severity={visualMessage.severity}
          onClose={visualMessage.onClose}
          autoClose={false}
        />
      )}
      <iframe
        title="QoloWidget"
        id="widget_iframe"
        width={isMobile ? screenWidth * 0.95 : "450"}
        height={isMobile ? screenHeight * 0.7 : "460"}
        style={{ border: "none" }}
      ></iframe>
    </>
  );
}
