import {
  Grid,
  Typography,
  useMediaQuery,
  SvgIcon,
  FormControl,
  OutlinedInput,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import * as React from "react";
import { Box } from "@mui/system";
import Menu from "../../components/ui/Menu";
import useLabels from "../../labels/useLabels";
import LoadingButton from "../../components/ui/LoadingButton";
import VisualMessage from "../../components/ui/VisualMessage";
import { ReactComponent as sendMoneyIcon } from "../../images/sendmoney.svg";
import CardService from "../../services/CardService";
import { useFormik } from "formik";
import * as validatorYup from "yup";
import { CircularProgress } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import HttpService from "../../services/HttpService";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import RecipientService from "../../services/payments/RecipientService";
import TransferFeeService from "../../services/payments/TransferFeeService";
import SuccessfullTransaction from "../../components/ui/SuccessfullTransaction";
import OTPInput from "../../components/ui/OTPInput";
import UserService from "../../services/UserService";
import QoloWidget from "../../components/ui/QoloWidget";
import MainBackground from "../../components/ui/MainBackground";
import { usePaymentToken } from "../../hooks/usePaymentHook";

export default function Own() {
  const { getLabel } = useLabels();
  const [visualMessage, setVisualMessage] = React.useState();
  const [visibleAddRecipient, setVisibleAddRecipient] = React.useState(false);
  const [widgetVisible, setWidgetVisible] = React.useState(false);
  const [transactionData, setTransactionData] = React.useState(null);
  const [askOTP, setAskOTP] = React.useState(false);
  const [alias, setAlias] = React.useState(""); // Track the alias for the widget
  const [fetchPaymentToken, setFetchPaymentToken] = React.useState(false); // Control when to fetch the token

  // Use the custom hook to fetch the payment token
  const responseUsePaymentToken = usePaymentToken(alias, fetchPaymentToken);
  const { data: paymentToken, isLoading: isPaymentTokenLoading } =
    responseUsePaymentToken;

  React.useEffect(() => {
    if (paymentToken) {
      setWidgetVisible(true); // Show the widget when the payment token is available
    }
  }, [paymentToken]);

  React.useEffect(() => {
    formikAddRecipient.resetForm();
    setWidgetVisible(false);
  }, [visibleAddRecipient]);

  const instantMutation = useMutation(
    (data) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + `/payment/transfer`,
        data
      );
    },
    {
      onSuccess: (value) => {
        if (
          value.status >= 200 &&
          value.status < 300 &&
          value?.data?.transactionId
        ) {
          setTransactionData(value.data);
        } else {
          setVisualMessage({
            title: getLabel("todo", "Error during instant send Money"),
            message: getLabel(
              "todo",
              "There was an error during instant send Money. Please try again later."
            ),
            onClose: () => setVisualMessage(),
          });
        }
      },
      onError: (values) => {
        let errorMessage =
          "There was an error during instant send Money. Please try again later.";
        if (values?.response?.data?.message) {
          errorMessage = values.response.data.message;
        }
        setVisualMessage({
          title: getLabel("todo", "Error during instant send Money"),
          message: getLabel("todo", errorMessage),
          onClose: () => setVisualMessage(),
        });
      },
    }
  );

  const addRecipientMutation = useMutation(
    (data) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + `/payment/recipient`,
        data
      );
    },
    {
      onSuccess: (value) => {
        setVisualMessage({
          title: getLabel("", "Recipient added successfully"),
          message: getLabel("", "The recipient was added successfully."),
          onClose: () => {
            setVisualMessage();
            onWidgetSuccess();
          },
        });
      },
      onError: (values) => {
        if (values.response.data) {
          setVisualMessage({
            title: getLabel("", "Error adding payee"),
            message: values.response.data,
            onClose: () => setVisualMessage(),
          });
        } else {
          setVisualMessage({
            title: getLabel("", "Error adding payee"),
            message:
              "There was an error adding the payee. Please try again later.",
            onClose: () => setVisualMessage(),
          });
        }
      },
    }
  );

  const traceEventMutation = useMutation(
    (data) => {
      return HttpService.getAxiosClient().post(window.API_URL + `/trace`, data);
    },
    {
      onSuccess: (value) => {},
      onError: (values) => {},
    }
  );

  const traceEvent = (
    alias,
    instrument_status,
    mask_account_number,
    status_reason
  ) => {
    const transformJsonTrace = () => {
      return {
        email: UserService.getUsername(),
        eventName: "card_validate",
        description: instrument_status,
        extraInfo01: alias,
        extraInfo02: mask_account_number,
        extraInfo06: status_reason,
        timestamp: Math.round(Date.now() / 1000),
      };
    };
    const transformedJsonTrace = transformJsonTrace();
    traceEventMutation.mutate(transformedJsonTrace);
  };

  const cardService = CardService();
  const transferFeeService = TransferFeeService("own");

  const validationSendMoneySchema = validatorYup.object({
    recipient: validatorYup
      .object()
      .required(getLabel("", "Recipient is required")),
    amount: validatorYup
      .number()
      .required("Amount is required")
      .moreThan(0, "Amount must be greater than zero")
      .typeError("Amount must be a number")
      .max(
        cardService && cardService.cardDetail
          ? cardService.cardDetail.availableBalance
          : 0,
        "Amount cannot exceed Available Balance"
      ),
  });

  const validationAddRecipientSchema = validatorYup.object({
    alias: validatorYup
      .string()
      .required(
        getLabel("sendMoney.recipient.add.firstName", "Alias is required")
      ),
  });

  const queryClient = useQueryClient();

  const formikAddRecipient = useFormik({
    initialValues: {
      alias: "",
    },
    validationSchema: validationAddRecipientSchema,
    onSubmit: (values) => {
      setAlias(values.alias); // Set the alias to trigger the payment token fetch
      setFetchPaymentToken(true); // Enable the query to fetch the payment token
    },
  });

  const formikInstantSendMoney = useFormik({
    initialValues: {
      recipient: null,
      amount: "",
    },
    validationSchema: validationSendMoneySchema,
    onSubmit: (values) => {
      const transformedJson = {
        firstName: null,
        lastName: null,
        amount: Number(values.amount),
        transferFrequency: "Today",
        idMoneyMovementBeneficiary: values.recipient.idMoneyMovementBeneficiary,
        emailOrPhone: null,
        idMoneyMovementItem: null,
        transferType: "instant",
        communicationType: null,
        typeMoneyMovementItem: null,
      };
      instantMutation.mutate(transformedJson);
    },
  });

  const recipientService = RecipientService("instant");

  const isMobile = useMediaQuery("(max-width:600px)");

  if (recipientService.isLoading || transferFeeService.isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  const onWidgetSuccess = () => {
    setWidgetVisible(false);
    setVisibleAddRecipient(false);
    setFetchPaymentToken(false); // Reset the fetch state
    queryClient.invalidateQueries(["recipients", "instant"]);
  };

  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;

  const FormAddRecipient = () => {
    return (
      <Dialog
        open={visibleAddRecipient}
        onClose={(event, reason) => {
          reason !== "backdropClick" && setVisibleAddRecipient(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        p={isMobile ? "2rem" : 0}
        m={isMobile ? "2rem" : 0}
        fullScreen={isMobile}
        fullWidth={!isMobile}
        disableEscapeKeyDown={true}
      >
        <DialogTitle sx={{ padding: 1, margin: 0 }}>
          {getLabel(
            "sendMoney.repcipient.add.title",
            "Add Recipient: " + formikAddRecipient?.values?.alias
          )}
        </DialogTitle>
        <Box
          noValidate
          autoComplete="off"
          sx={{ width: "340px", height: "510px" }}
        >
          <DialogContent
            sx={{
              margin: 0,
              pt: 1,
              pl: 1,
              pr: 1,
              width: isMobile ? screenWidth * 0.95 : 450,
              height: isMobile ? screenHeight * 0.95 : 660,
            }}
          >
            <Grid
              container
              sx={{
                padding: 0,
                margin: 0,
              }}
            >
              {!widgetVisible ? (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Set an Alias"
                    id="alias"
                    type={"text"}
                    value={formikAddRecipient.values.alias}
                    onChange={formikAddRecipient.handleChange}
                    onBlur={formikAddRecipient.handleBlur}
                    error={
                      formikAddRecipient.touched.alias &&
                      Boolean(formikAddRecipient.errors.alias)
                    }
                    helperText={
                      formikAddRecipient.touched.alias &&
                      formikAddRecipient.errors.alias
                    }
                  />
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <QoloWidget
                    alias={formikAddRecipient.values.alias}
                    onWidgetSuccess={onWidgetSuccess}
                    addRecipientMutation={addRecipientMutation}
                    traceEvent={traceEvent}
                    isOwn={true}
                    paymentToken={paymentToken?.token}
                  />
                </Grid>
              )}
            </Grid>
          </DialogContent>
        </Box>
        <DialogActions>
          {!widgetVisible ? (
            <LoadingButton
              variant="contained"
              size="small"
              onClick={() => {
                if (
                  formikAddRecipient.values.alias !== undefined &&
                  formikAddRecipient.values.alias !== ""
                ) {
                  formikAddRecipient.handleSubmit(); // Trigger the form submission

                  //getPaymentToken(formikAddRecipient.values.alias);
                }
              }}
            >
              {getLabel("", "Continue")}{" "}
            </LoadingButton>
          ) : (
            <></>
          )}
          <LoadingButton
            variant="contained"
            size="small"
            onClick={() => setVisibleAddRecipient(false)}
            color="secondary"
          >
            {getLabel("recipient.add.close", "Close")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  };

  const handleOnSuccessOTP = () => {
    setAskOTP(false);
    formikInstantSendMoney.handleSubmit();
  };

  const FormOTP = () => (
    <Dialog
      open={askOTP}
      fullScreen
      onClose={(event, reason) => {
        reason !== "backdropClick" && setAskOTP(false);
      }}
      disableEscapeKeyDown={true}
      aria-labelledby="otp-dialog-title"
    >
      <DialogTitle id="otp-dialog-title" sx={{ textAlign: "center" }}>
              {getLabel("otp.dialog.title", "One Time Password")}
      </DialogTitle>
      <OTPInput
        recipient={UserService.getUsername()}
        from={"instant-send"}
        onClose={() => setAskOTP(false)}
        onSuccess={() => handleOnSuccessOTP()}
        handleComplete={() => {
          setAskOTP(false);
          formikInstantSendMoney.handleSubmit();
        }}
      />
    </Dialog>
  );

  const handleSuccessfullTransactionClose = () => {
    formikInstantSendMoney.resetForm();
    setTransactionData();
  };

  return (
    <>
      <FormOTP />
      <div style={{ display: true ? "block" : "none" }}>
        {transactionData && (
          <SuccessfullTransaction
            message={`Your reference number is: ${transactionData.transactionId}`}
            onClose={handleSuccessfullTransactionClose}
          />
        )}
        {visualMessage && (
          <VisualMessage
            title={visualMessage.title}
            message={visualMessage.message}
            severity={visualMessage.severity}
            onClose={visualMessage.onClose}
          />
        )}
        <MainBackground
          component="form"
          noValidate
          autoComplete="off"
          isMobile={isMobile}
        >
          <Grid
            container
            style={{
              width: isMobile ? "100%" : "65%",
              margin: !isMobile ? "3rem auto" : 0,
            }}
          >
            <Grid item xs={12} p={1}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <SvgIcon
                  fontSize="medium"
                  component={sendMoneyIcon}
                  inheritViewBox
                />
                <Typography variant="h6" sx={{ fontWeight: "bold" }} pl={1}>
                  {getLabel("sendMoney.others.title", "Instant Send Money")}
                </Typography>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              pt={4}
              alignContent={"center"}
              textAlign={"center"}
            >
              <Autocomplete
                variant="outlined"
                disablePortal
                id="recipient"
                options={recipientService.recipients.beneficiaries
                  .filter((item) => item.isOwn)
                  .filter((value, index, self) => {
                    return (
                      self.findIndex(
                        (v) => v.alias === value.alias && v.mask === value.mask
                      ) === index
                    );
                  })
                  .map((item) => {
                    return {
                      id: item.idMoneyMovementItem,
                      typeMoneyMovementItem: item.typeMoneyMovementItem,
                      idMoneyMovementBeneficiary:
                        item.idMoneyMovementBeneficiary,
                      idCommunication: item.idCommunication,
                      typeCommunication: item.typeCommunication,
                      firstName: item.firstName,
                      lastName: item.lastName,
                      label: item.alias + " (" + item.mask + ")",
                    };
                  })}
                value={formikInstantSendMoney.values.recipient}
                onChange={(event, newValue) => {
                  formikInstantSendMoney.setFieldValue("recipient", newValue);
                }}
                isOptionEqualToValue={(option, value) => {
                  return option.id === value.id;
                }}
                sx={{ maxWidth: "80rem", margin: "auto", bgcolor: "white" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="recipient"
                    error={
                      formikInstantSendMoney.touched.recipient &&
                      Boolean(formikInstantSendMoney.errors.recipient)
                    }
                    helperText={
                      formikInstantSendMoney.touched.recipient &&
                      formikInstantSendMoney.errors.recipient
                    }
                    label="Recipient"
                    onBlur={formikInstantSendMoney.handleBlur}
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} pt={0}>
              <Grid
                container
                sx={{ maxWidth: "80rem", margin: "auto" }}
                justifyContent={"flex-end"}
              >
                <Grid item>
                  <LoadingButton
                    variant="contained"
                    sx={{
                      fontSize: "0.8rem",
                      marginTop: "0.5rem",
                      marginBottom: "1rem",
                    }}
                    color="secondary"
                    onClick={() => {
                      setVisibleAddRecipient(true);
                    }}
                  >
                    Add Recipient
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              pt={1}
              alignContent={"center"}
              textAlign={"center"}
            >
              <FormControl
                fullWidth
                variant="outlined"
                sx={{ maxWidth: "80rem", margin: "auto" }}
              >
                <OutlinedInput
                  id="amount"
                  fullWidth
                  sx={{
                    bgcolor: "white",
                    maxWidth: "80rem",
                    margin: "auto",
                  }}
                  type={"text"}
                  placeholder={getLabel("sendMoney.amount", "Amount")}
                  value={formikInstantSendMoney.values.amount}
                  onBlur={formikInstantSendMoney.handleBlur}
                  onChange={formikInstantSendMoney.handleChange}
                  error={
                    formikInstantSendMoney.touched.amount &&
                    Boolean(formikInstantSendMoney.errors.amount)
                  }
                />
                <FormHelperText sx={{ color: "red" }}>
                  {formikInstantSendMoney.errors.amount}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} pt={0}>
              <Typography variant="body2" sx={{ textAlign: "center" }}>
                {getLabel(
                  "sendMoney.available",
                  "Available Balance: " +
                    new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(
                      cardService && cardService.cardDetail
                        ? cardService.cardDetail.availableBalance
                        : 0
                    )
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} pt={0}>
              <Typography
                variant="body2"
                sx={{ textAlign: "right" }}
                color={"secondary"}
              >
                {getLabel(
                  "sendMoney.fee",
                  "Fee: " +
                    new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(transferFeeService.fee.fee)
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} pt={3}>
              <LoadingButton
                sx={{
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                }}
                variant="contained"
                color="primary"
                fullWidth
                loading={instantMutation.isLoading}
                onClick={() => {
                  if (Object.keys(formikInstantSendMoney.errors).length === 0) {
                    setAskOTP(true);
                  }
                }}
              >
                Instant Send
              </LoadingButton>
            </Grid>
          </Grid>
        </MainBackground>
        {visibleAddRecipient && FormAddRecipient()}
        <Menu isMobile={isMobile} />
      </div>
    </>
  );
}
