import {
  Button,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  SvgIcon,
  Box,
  Chip,
} from "@mui/material";
import { CircularProgress } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useInfiniteQuery } from "@tanstack/react-query";
import HttpService from "../../services/HttpService";
import { useNavigate } from "react-router-dom";
import VisualMessage from "../../components/ui/VisualMessage";
import React from "react";
import { ReactComponent as billPayIcon } from "../../images/billpay.svg";
import useLabels from "../../labels/useLabels";
import Menu from "../../components/ui/Menu";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import BlockIcon from "@mui/icons-material/Block";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import PendingIcon from "@mui/icons-material/Pending";
import { useInView } from "react-intersection-observer";
import SpeedIcon from "@mui/icons-material/Speed";
import TodayIcon from "@mui/icons-material/Today";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import { useQueryClient } from "@tanstack/react-query";
import MainBackground from "../../components/ui/MainBackground";
import { getDateDaysAgo, getTomorrowsDate } from "../../utils/dateUtils";

export default function BillPayments() {
  const { getLabel } = useLabels();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:600px)");
  const isStandardScreen = useMediaQuery("(max-width:1200px)");
  const isMediumScreen = useMediaQuery("(max-width:1400px)");
  const isLargeScreen = useMediaQuery("(min-width:1400px)");
  const weekday = [
    "SUNDAYS",
    "MONDAYS",
    "TUESDAYS",
    "WEDNESDAYS",
    "THURSDAYS",
    "FRIDAYS",
    "SATURDAYS",
  ];

  const queryClient = useQueryClient();

  const [cancelId, setCancelId] = React.useState();
  const [resendingId, setResendingId] = React.useState();
  const [visualMessage, setVisualMessage] = React.useState();

  const [sendMoneyTypeSelection, setSendMoneyTypeSelection] =
    React.useState("nextDay");

  async function fetchTransfers({ pageParam = 0, queryKey }) {
    const [_key, sendMoneyTypeSelection] = queryKey;
    const startDate = getDateDaysAgo(90);
    const endDate = getTomorrowsDate();
    const response = await HttpService.getAxiosClient().get(
      window.API_URL +
        "/payment/billpay?from=" +
        startDate +
        "&to=" +
        endDate +
        "&type=" +
        sendMoneyTypeSelection +
        "&cursor=" +
        pageParam
    );
    return { data: response.data, nextPage: pageParam + 1 };
  }

  const { ref, inView } = useInView();

  const cancelBillPaymentMutation = useMutation(
    (idMoneyMovement) => {
      return HttpService.getAxiosClient().delete(
        window.API_URL +
          `/payment/billpay/` +
          idMoneyMovement +
          `?type=` +
          sendMoneyTypeSelection
      );
    },
    {
      onSuccess: (value) => {
        setVisualMessage({
          title: getLabel("transfer.cancel.ok.title", "Send money cancelled"),
          message: getLabel(
            "transfer.cancel.ok.message",
            "The transfer was successfully cancelled"
          ),
          severity: "success",
          onClose: () => {
            setVisualMessage();
            queryClient.invalidateQueries({ queryKey: ["transfers"] });

            //navigate("/sendMoney");
          },
        });
        setIsSending(false);
      },
      onError: (values) => {
        setVisualMessage({
          title: getLabel(
            "transfer.cancel.error.title",
            "Send money not cancelled"
          ),
          message: getLabel(
            "transfer.cancel.error.message",
            "The transfer was not successfully cancelled"
          ),
          onClose: () => setVisualMessage(),
        });
        setIsSending(false);
      },
    }
  );

  const [isSending, setIsSending] = React.useState(false);

  async function getInitials(subdomain) {}

  React.useEffect(() => {
    getInitials();
  }, []);

  const handleSendClick = (id) => {
    setIsSending(true);
    setResendingId(id);
    //resendTransferlinkMutation.mutate(id);
  };

  const handleCancelClick = (id) => {
    setIsSending(true);
    setCancelId(id);
    cancelBillPaymentMutation.mutate(id);
  };

  const {
    status,
    data,
    error,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ["transfers", sendMoneyTypeSelection],
    queryFn: fetchTransfers,
    refetchOnWindowFocus: false,
    getNextPageParam: (lastPage, allPages) => {
      if (
        sendMoneyTypeSelection === "instant" &&
        lastPage.data !== null &&
        lastPage.data.transfers.length > 0
      )
        return lastPage.nextPage;
      return undefined;
    },
  });

  React.useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  return (
    <div style={{ display: true ? "block" : "none" }}>
      {visualMessage && (
        <VisualMessage
          title={visualMessage.title}
          message={visualMessage.message}
          severity={visualMessage.severity}
          onClose={visualMessage.onClose}
        />
      )}
      <MainBackground isMobile={isMobile}>
        <Grid
          container
          style={{
            width: isMobile ? "100%" : "65%",
            margin: !isMobile ? "3rem auto" : 0,
          }}
        >
          <Grid item xs={12} p={1}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <SvgIcon
                fontSize="medium"
                component={billPayIcon}
                inheritViewBox
              />
              <Typography variant="h6" sx={{ fontWeight: "bold" }} pl={1}>
                Bill Pay Transactions
              </Typography>
            </div>
            {/*<br></br>
            <div style={{ display: "flex", justifyContent: "center" }}>
            <Typography variant="body1" sx={{ fontWeight: "normal" }} pl={1}>
            To provide you with accurate financial records, kindly select your preferred monthly statement from the list below
            </Typography>


            </div>*/}
          </Grid>
          {status === "loading" ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                width: "100%",
              }}
            >
              <CircularProgress color="secondary" />
            </Box>
          ) : status === "error" ? (
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold" }}
              color="secondary"
            >
              Error: {error.message}
            </Typography>
          ) : (
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "1rem",
                  maxWidth: isMobile ? "95%" : "95%",
                  minWidth: isMobile ? "90%" : "95%",
                }}
              >
                <Paper
                  xs={12}
                  sx={{
                    borderRadius: "10px",
                    backgroundColor: "rgba(255, 255, 255, 0.25)",
                    margin: "0.5rem",
                    minWidth: isMobile ? "100%" : "95%",
                  }}
                  elevation={4}
                >
                  <br></br>
                  <div
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    <Chip
                      onClick={() => {
                        setSendMoneyTypeSelection("nextDay");
                        queryClient.invalidateQueries({
                          queryKey: ["transfers"],
                        });
                      }}
                      icon={<TodayIcon />}
                      label="Next Day"
                      variant={
                        sendMoneyTypeSelection === "nextDay"
                          ? "outlined"
                          : "filled"
                      }
                    />
                    <Chip
                      onClick={() => {
                        setSendMoneyTypeSelection("scheduled");
                        queryClient.invalidateQueries({
                          queryKey: ["transfers"],
                        });
                      }}
                      icon={<HistoryToggleOffIcon />}
                      label="Scheduled"
                      variant={
                        sendMoneyTypeSelection === "scheduled"
                          ? "outlined"
                          : "filled"
                      }
                    />
                  </div>
                  <br></br>

                  {data.pages.map((page) => (
                    <React.Fragment key={page.nextPage}>
                      {page?.data?.transfers?.map((transfer) => {
                        let processingDate = new Date(
                          transfer?.processingDate
                            ?.split("[")[0]
                            .replace("Z", "")
                        ); //.split('Z')[0]//.toLocaleString('en-US').replace(',', '')
                        let creationDate = new Date(
                          transfer?.creationDate?.split("[")[0].replace("Z", "")
                        ); //.split('Z')[0]//.toLocaleString('en-US').replace(',', '')
                        let dayOfTheWeek =
                          weekday[
                            new Date(Date.parse(processingDate))?.getDay()
                          ];
                        let dayOfTheMonth = new Date(
                          Date.parse(processingDate)
                        )?.getDate();

                        return (
                          <Grid
                            item
                            xs={12}
                            textAlign={"left"}
                            padding={"0.5rem"}
                            marginInline={"0.5rem"}
                            key={
                              sendMoneyTypeSelection === "instant"
                                ? transfer?.id
                                : transfer?.transferId
                            }
                          >
                            <Box display="flex" alignItems="center" gap={2}>
                              {!isMobile && !isStandardScreen ? (
                                <Typography
                                  sx={{
                                    color: "grey",
                                    minWidth: "12rem",
                                    maxWidth: "12rem",
                                    fontSize: "1rem",
                                  }}
                                  alignItems={"center"}
                                >
                                  {sendMoneyTypeSelection === "instant"
                                    ? new Date(creationDate)
                                        .toLocaleString("en-US")
                                        .replace(",", "")
                                    : ""}
                                  {sendMoneyTypeSelection === "nextDay"
                                    ? new Date(processingDate)
                                        .toLocaleString("en-US")
                                        .replace(",", "")
                                    : ""}
                                  {sendMoneyTypeSelection === "scheduled" &&
                                  transfer?.paymentFrequency === "Weekly"
                                    ? "ON " + dayOfTheWeek
                                    : ""}
                                  {sendMoneyTypeSelection === "scheduled" &&
                                  transfer?.paymentFrequency === "Monthly"
                                    ? "ON DAY " + dayOfTheMonth
                                    : ""}
                                  {sendMoneyTypeSelection === "scheduled" &&
                                  transfer?.paymentFrequency === "OneTime"
                                    ? new Date(processingDate)
                                        .toLocaleString("en-US")
                                        .replace(",", "")
                                    : ""}
                                </Typography>
                              ) : (
                                <></>
                              )}

                              {(transfer.status === "COMPLETED" ||
                                transfer.status === "Sent") &&
                              isMobile ? (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  flexDirection="row"
                                  minWidth="1rem"
                                  maxWidth="1rem"
                                  color="green"
                                >
                                  <CheckCircleOutlineIcon />{" "}
                                </Box>
                              ) : (
                                <></>
                              )}
                              {transfer.status === "QOLO_RECIPIENT" &&
                              isMobile ? (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  flexDirection="row"
                                  minWidth="1rem"
                                  maxWidth="1rem"
                                  color="orange"
                                >
                                  <PendingIcon />{" "}
                                </Box>
                              ) : (
                                <></>
                              )}
                              {transfer.status !== "COMPLETED" &&
                              transfer.status !== "Sent" &&
                              transfer.status !== "QOLO_RECIPIENT" &&
                              transfer?.status !== "Pending" &&
                              transfer?.status !== "InProcess" &&
                              sendMoneyTypeSelection !== "scheduled" &&
                              isMobile ? (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  flexDirection="row"
                                  minWidth="1rem"
                                  maxWidth="1rem"
                                  color="red"
                                >
                                  <BlockIcon />{" "}
                                </Box>
                              ) : (
                                <></>
                              )}
                              {(transfer.status === "Pending" ||
                                sendMoneyTypeSelection === "scheduled") &&
                              (transfer?.paymentFrequency === "Weekly" ||
                                transfer?.paymentFrequency === "Monthly" ||
                                transfer?.paymentFrequency === "OneTime") &&
                              isMobile ? (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  flexDirection="row"
                                  minWidth="1rem"
                                  maxWidth="1rem"
                                  color="orange"
                                >
                                  <AlarmOnIcon />{" "}
                                </Box>
                              ) : (
                                <></>
                              )}
                              {transfer.status === "InProcess" &&
                              (transfer?.paymentFrequency === "Weekly" ||
                                transfer?.paymentFrequency === "Monthly" ||
                                transfer?.paymentFrequency === "OneTime") &&
                              isMobile ? (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  flexDirection="row"
                                  minWidth="1rem"
                                  maxWidth="1rem"
                                  color="orange"
                                >
                                  <PendingIcon />{" "}
                                </Box>
                              ) : (
                                <></>
                              )}

                              <Box
                                display="flex"
                                alignItems="center"
                                flexDirection="column"
                              >
                                <Grid
                                  borderColor={"primary"}
                                  item
                                  xs={12}
                                  p={0}
                                  m={0}
                                  r={1}
                                  ml={{ md: 3 }}
                                  mr={{ xs: 1, md: 3 }}
                                  sx={{
                                    border: "2px solid",
                                    borderRadius: "20px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      minWidth: "6rem",
                                      maxWidth: "6rem",
                                      padding: "0.1rem",
                                      fontSize: "1rem",
                                      textAlign: "center",
                                    }}
                                  >
                                    $
                                    {sendMoneyTypeSelection === "instant"
                                      ? transfer?.referenceAmount?.toFixed(2)
                                      : transfer?.amount?.toFixed(2)}
                                  </Typography>
                                </Grid>
                              </Box>

                              <Box
                                display="flex"
                                alignItems="center"
                                flexDirection={"column"}
                              >
                                <Typography
                                  sx={{
                                    minWidth: isMobile ? "9rem" : "14rem",
                                    maxWidth: isMobile ? "9rem" : "14rem",
                                    fontSize: "1rem",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {transfer?.moneyMovementBeneficiary?.isOwn ===
                                  true
                                    ? transfer?.moneyMovementBeneficiary?.alias
                                    : transfer?.moneyMovementBeneficiary
                                        ?.firstName +
                                      " (" +
                                      transfer?.moneyMovementBeneficiary
                                        ?.lastName +
                                      ") "}
                                </Typography>
                                <div
                                  style={{
                                    maxWidth: isMobile ? "9rem" : "14rem",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      minWidth: isMobile ? "9rem" : "14rem",
                                      maxWidth: isMobile ? "9rem" : "14rem",
                                      fontSize: "0.9rem",
                                      color: "black",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {transfer?.moneyMovementBeneficiary
                                      ?.isOwn === true
                                      ? transfer?.moneyMovementBeneficiary?.mask
                                      : "xxxx" +
                                        transfer?.moneyMovementBeneficiary
                                          ?.idCommunication}
                                  </Typography>
                                </div>
                                {isMobile || isStandardScreen ? (
                                  <Typography
                                    sx={{
                                      color: "grey",
                                      minWidth: isMobile ? "9rem" : "14rem",
                                      maxWidth: isMobile ? "9rem" : "14rem",
                                      fontSize: "0.8rem",
                                    }}
                                    alignItems={"center"}
                                  >
                                    {sendMoneyTypeSelection === "instant"
                                      ? new Date(creationDate)
                                          .toLocaleString("en-US")
                                          .replace(",", "")
                                      : ""}
                                    {sendMoneyTypeSelection === "nextDay"
                                      ? new Date(processingDate)
                                          .toLocaleString("en-US")
                                          .replace(",", "")
                                      : ""}
                                    {sendMoneyTypeSelection === "scheduled" &&
                                    transfer?.paymentFrequency === "Weekly"
                                      ? "ONCE A WEEK ON " + dayOfTheWeek
                                      : ""}
                                    {sendMoneyTypeSelection === "scheduled" &&
                                    transfer?.paymentFrequency === "Monthly"
                                      ? "ONCE A MONTH ON DAY " + dayOfTheMonth
                                      : ""}
                                    {sendMoneyTypeSelection === "scheduled" &&
                                    transfer?.paymentFrequency === "OneTime"
                                      ? new Date(processingDate)
                                          .toLocaleString("en-US")
                                          .replace(",", "")
                                      : ""}
                                  </Typography>
                                ) : (
                                  <></>
                                )}
                              </Box>
                              {(transfer.status === "COMPLETED" ||
                                transfer.status === "Sent") &&
                              !isMobile ? (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  flexDirection="row"
                                  minWidth="1rem"
                                  maxWidth="1rem"
                                  color="green"
                                >
                                  <CheckCircleOutlineIcon />{" "}
                                </Box>
                              ) : (
                                <></>
                              )}
                              {transfer.status === "QOLO_RECIPIENT" &&
                              !isMobile ? (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  flexDirection="row"
                                  minWidth="1rem"
                                  maxWidth="1rem"
                                  color="orange"
                                >
                                  <PendingIcon />{" "}
                                </Box>
                              ) : (
                                <></>
                              )}
                              {transfer.status !== "COMPLETED" &&
                              transfer.status !== "Sent" &&
                              transfer.status !== "QOLO_RECIPIENT" &&
                              transfer?.status !== "Pending" &&
                              transfer?.status !== "InProcess" &&
                              sendMoneyTypeSelection !== "scheduled" &&
                              !isMobile ? (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  flexDirection="row"
                                  minWidth="1rem"
                                  maxWidth="1rem"
                                  color="red"
                                >
                                  <BlockIcon />{" "}
                                </Box>
                              ) : (
                                <></>
                              )}
                              {(transfer.status === "Pending" ||
                                sendMoneyTypeSelection === "scheduled") &&
                              (transfer?.paymentFrequency === "Weekly" ||
                                transfer?.paymentFrequency === "Monthly" ||
                                transfer?.paymentFrequency === "OneTime") &&
                              !isMobile ? (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  flexDirection="row"
                                  minWidth="1rem"
                                  maxWidth="1rem"
                                  color="orange"
                                >
                                  <AlarmOnIcon />{" "}
                                </Box>
                              ) : (
                                <></>
                              )}
                              {transfer.status === "InProcess" &&
                              (transfer?.paymentFrequency === "Weekly" ||
                                transfer?.paymentFrequency === "Monthly" ||
                                transfer?.paymentFrequency === "OneTime") &&
                              !isMobile ? (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  flexDirection="row"
                                  minWidth="1rem"
                                  maxWidth="1rem"
                                  color="orange"
                                >
                                  <PendingIcon />{" "}
                                </Box>
                              ) : (
                                <></>
                              )}

                              {(transfer.status === "COMPLETED" ||
                                transfer.status === "Sent") &&
                              !isMobile &&
                              !isStandardScreen &&
                              !isMediumScreen ? (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  flexDirection="row"
                                  color="green"
                                  minWidth={isMobile ? "8rem" : "14rem"}
                                  maxWidth={isMobile ? "8rem" : "14rem"}
                                >
                                  {" "}
                                  <Typography fontSize="1rem">
                                    {" "}
                                    MONEY SENT
                                  </Typography>
                                </Box>
                              ) : (
                                <></>
                              )}
                              {transfer.status === "QOLO_RECIPIENT" &&
                              !isMobile &&
                              !isStandardScreen &&
                              !isMediumScreen ? (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  flexDirection="row"
                                  color="orange"
                                  minWidth={isMobile ? "8rem" : "14rem"}
                                  maxWidth={isMobile ? "8rem" : "14rem"}
                                >
                                  {" "}
                                  <Typography fontSize="1rem">
                                    {" "}
                                    WAITING FOR RECIPIENT
                                  </Typography>
                                </Box>
                              ) : (
                                <></>
                              )}
                              {transfer.status !== "COMPLETED" &&
                              transfer.status !== "Sent" &&
                              transfer.status !== "QOLO_RECIPIENT" &&
                              transfer?.status !== "Pending" &&
                              transfer?.status !== "InProcess" &&
                              !isMobile &&
                              !isStandardScreen &&
                              !(sendMoneyTypeSelection === "scheduled") &&
                              !isMediumScreen ? (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  flexDirection="row"
                                  color="red"
                                  minWidth={isMobile ? "8rem" : "14rem"}
                                  maxWidth={isMobile ? "8rem" : "14rem"}
                                >
                                  {" "}
                                  <Typography fontSize="1rem">
                                    {" "}
                                    NOT SENT
                                  </Typography>
                                </Box>
                              ) : (
                                <></>
                              )}
                              {transfer.status === "InProcess" &&
                              !isMobile &&
                              !isStandardScreen &&
                              !isMediumScreen ? (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  flexDirection="row"
                                  color="orange"
                                  minWidth={isMobile ? "8rem" : "14rem"}
                                  maxWidth={isMobile ? "8rem" : "14rem"}
                                >
                                  {" "}
                                  <Typography fontSize="1rem">
                                    {" "}
                                    PROCESSING
                                  </Typography>
                                </Box>
                              ) : (
                                <></>
                              )}

                              {sendMoneyTypeSelection === "scheduled" &&
                              transfer?.paymentFrequency === "Weekly" &&
                              !isMobile &&
                              !isStandardScreen ? (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  flexDirection="row"
                                  color="orange"
                                  minWidth={isMobile ? "5rem" : "5rem"}
                                  maxWidth={isMobile ? "5rem" : "5rem"}
                                >
                                  {" "}
                                  <Typography fontSize="1rem">
                                    {" "}
                                    WEEKLY
                                  </Typography>
                                </Box>
                              ) : (
                                <></>
                              )}
                              {sendMoneyTypeSelection === "scheduled" &&
                              transfer?.paymentFrequency === "Monthly" &&
                              !isMobile &&
                              !isStandardScreen ? (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  flexDirection="row"
                                  color="orange"
                                  minWidth={isMobile ? "5rem" : "5rem"}
                                  maxWidth={isMobile ? "5rem" : "5rem"}
                                >
                                  {" "}
                                  <Typography fontSize="1rem">
                                    {" "}
                                    MONTHLY
                                  </Typography>
                                </Box>
                              ) : (
                                <></>
                              )}
                              {sendMoneyTypeSelection === "scheduled" &&
                              transfer?.paymentFrequency === "OneTime" &&
                              !isMobile &&
                              !isStandardScreen ? (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  flexDirection="row"
                                  color="orange"
                                  minWidth={isMobile ? "5rem" : "5rem"}
                                  maxWidth={isMobile ? "5rem" : "5rem"}
                                >
                                  {" "}
                                  <Typography fontSize="1rem">
                                    {" "}
                                    ONE TIME
                                  </Typography>
                                </Box>
                              ) : (
                                <></>
                              )}

                              {!(
                                (transfer?.status === "Pending" &&
                                  transfer?.isDeletable === true) ||
                                transfer.status === "QOLO_RECIPIENT"
                              ) ? (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  flexDirection="row"
                                  color="red"
                                  minWidth="1rem"
                                  maxWidth="1rem"
                                ></Box>
                              ) : null}

                              {transfer.status === "QOLO_RECIPIENT" ? (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  flexDirection="row"
                                  color="primary"
                                  minWidth="1rem"
                                  maxWidth="1rem"
                                  onClick={() => handleSendClick(transfer.id)}
                                >
                                  {" "}
                                  {isSending &&
                                  resendingId === transfer?.transferId ? (
                                    <CircularProgress
                                      color="secondary"
                                      size="1rem"
                                    />
                                  ) : (
                                    <ForwardToInboxIcon size="1rem" />
                                  )}{" "}
                                </Box>
                              ) : null}
                              {transfer.status === "QOLO_RECIPIENT" &&
                              !isMobile ? (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  flexDirection="row"
                                  color="primary"
                                >
                                  {" "}
                                  <Typography fontSize="1rem">
                                    {" "}
                                    RESEND LINK
                                  </Typography>{" "}
                                </Box>
                              ) : (
                                <></>
                              )}

                              {(transfer?.status === "Pending" ||
                                sendMoneyTypeSelection === "scheduled") &&
                              transfer?.isDeletable === true ? (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  flexDirection="row"
                                  color="red"
                                  minWidth="1rem"
                                  maxWidth="1rem"
                                  onClick={() =>
                                    handleCancelClick(transfer.transferId)
                                  }
                                >
                                  {" "}
                                  {isSending &&
                                  cancelId === transfer?.transferId ? (
                                    <CircularProgress
                                      color="secondary"
                                      size="1rem"
                                    />
                                  ) : (
                                    <BlockIcon size="1rem" />
                                  )}{" "}
                                </Box>
                              ) : null}
                              {(transfer?.status === "Pending" ||
                                sendMoneyTypeSelection === "scheduled") &&
                              transfer?.isDeletable === true &&
                              !isMobile &&
                              !isStandardScreen ? (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  flexDirection="row"
                                  color="red"
                                >
                                  {" "}
                                  <Typography fontSize="1rem">
                                    {" "}
                                    CANCEL
                                  </Typography>{" "}
                                </Box>
                              ) : (
                                <></>
                              )}
                            </Box>
                          </Grid>
                        );
                      })}
                    </React.Fragment>
                  ))}
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      ref={ref}
                      size="small"
                      onClick={() => fetchNextPage()}
                      disabled={!hasNextPage || isFetchingNextPage}
                      sx={{ textAlign: "center" }}
                    >
                      {isFetchingNextPage
                        ? getLabel(
                            "cardTransaction.loading",
                            "Loading more transactions..."
                          )
                        : hasNextPage
                        ? ""
                        : getLabel(
                            "cardTransaction.noMore",
                            "No more transactions"
                          )}
                    </Button>
                  </Grid>
                </Paper>
              </div>
            </Grid>
          )}
          <Grid item xs={12} pt={0}>
            <div
              style={{
                backgroundColor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            ></div>
          </Grid>
        </Grid>
      </MainBackground>
      <Menu isMobile={isMobile} />
    </div>
  );
}
