import * as React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import RecoveryPasswordStep1 from "./pages/RecoveryPasswordStep1";
import RenderOnAuthenticated from "./components/security/RenderOnAuthenticated";
import RenderOnAnonymous from "./components/security/RenderOnAnonymous";
import MainSite from "./pages/MainSite";
import ChangePin from "./pages/ChangePin";
import Pad from "./pages/Pad";
import Lock from "./pages/Lock";
import Unlock from "./pages/Unlock";
import ReplaceCard from "./pages/ReplaceCard";
import BillPay from "./pages/billPay/BillPay";
import Instant from "./pages/sendMoney/Instant";
import Statements from "./pages/Statements";
import Transfers from "./pages/sendMoney/Transfers";
import SendMoney from "./pages/sendMoney/SendMoney";
import NextDay from "./pages/sendMoney/NextDay";
import ToOthers from "./pages/sendMoney/ToOthers";
import Own from "./pages/sendMoney/Own";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Beneficiaries from "./pages/sendMoney/Beneficiaries";
import Notifications from "./pages/config/Notifications";
import Profile from "./pages/profile/Profile";
import AccountInfo from "./pages/profile/AccountInfo";
import Agreements from "./pages/profile/Agreements";
import CardAdmin from "./pages/CardAdmin";
import ChangeLanguage from "./pages/profile/ChangeLanguage";
import ChangePassword from "./pages/profile/ChangePassword";
import MainOnboarding from "./pages/onboarding/MainOnboarding";
import MainExternalPayment from "./pages/externalPayments/MainExternalPayment";
import MainFundsReceive from "./pages/fundsReceive/MainFundsReceive";
import ErrorPage from "./pages/ErrorPage";
import { CompanyOnboarding } from "./pages/onboarding/assisted/CompanyOnboarding";
import CardNotFound from "./pages/CardNotFound";
import BillPayments from "./pages/billPay/BillPayments";
export default function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<LoginPage />} />
          <Route exact path="/login/:username" element={<LoginPage />} />
          <Route
            exact
            path="/recoveryPassword/step1"
            element={<RecoveryPasswordStep1 />}
          />
          <Route
            exact
            path="/home"
            element={
              <RenderOnAuthenticated>
                <MainSite />
              </RenderOnAuthenticated>
            }
          />
          <Route
            exact
            path="/pad"
            element={
              <RenderOnAuthenticated>
                <Pad />
              </RenderOnAuthenticated>
            }
          />
          <Route
            exact
            path="/billPay"
            element={
              <RenderOnAuthenticated>
                <BillPay />
              </RenderOnAuthenticated>
            }
          />
          <Route
            exact
            path="/sendMoney"
            element={
              <RenderOnAuthenticated>
                <SendMoney />
              </RenderOnAuthenticated>
            }
          />
          <Route
            exact
            path="/sendMoney/nextDay"
            element={
              <RenderOnAuthenticated>
                <NextDay />
              </RenderOnAuthenticated>
            }
          />
          <Route
            exact
            path="/sendMoney/instant"
            element={
              <RenderOnAuthenticated>
                <Instant />
              </RenderOnAuthenticated>
            }
          />
          <Route
            exact
            path="/sendMoney/instant/own"
            element={
              <RenderOnAuthenticated>
                <Own />
              </RenderOnAuthenticated>
            }
          />
          <Route
            exact
            path="/sendMoney/instant/others"
            element={
              <RenderOnAuthenticated>
                <ToOthers />
              </RenderOnAuthenticated>
            }
          />
          <Route
            exact
            path="/changePin"
            element={
              <RenderOnAuthenticated>
                <ChangePin />
              </RenderOnAuthenticated>
            }
          />
          <Route
            exact
            path="/lock"
            element={
              <RenderOnAuthenticated>
                <Lock />
              </RenderOnAuthenticated>
            }
          />
          <Route
            exact
            path="/unlock"
            element={
              <RenderOnAuthenticated>
                <Unlock />
              </RenderOnAuthenticated>
            }
          />
          <Route
            exact
            path="/replaceCard"
            element={
              <RenderOnAuthenticated>
                <ReplaceCard />
              </RenderOnAuthenticated>
            }
          />
          <Route
            exact
            path="/accountInfo/statements"
            element={
              <RenderOnAuthenticated>
                <Statements />
              </RenderOnAuthenticated>
            }
          />
          <Route
            exact
            path="/creditCards"
            element={
              <RenderOnAuthenticated>
                <Statements />
              </RenderOnAuthenticated>
            }
          />
          <Route
            exact
            path="/transfers"
            element={
              <RenderOnAuthenticated>
                <Transfers />
              </RenderOnAuthenticated>
            }
          />
          <Route
            exact
            path="/billPayments"
            element={
              <RenderOnAuthenticated>
                <BillPayments />
              </RenderOnAuthenticated>
            }
          />
          <Route
            exact
            path="/transfers/recipients/manage/:sendMoneyTypeSelection"
            element={
              <RenderOnAuthenticated>
                <Beneficiaries />
              </RenderOnAuthenticated>
            }
          />
          <Route
            exact
            path="/config/notifications"
            element={
              <RenderOnAuthenticated>
                <Notifications />
              </RenderOnAuthenticated>
            }
          />
          <Route
            exact
            path="/profile"
            element={
              <RenderOnAuthenticated>
                <Profile />
              </RenderOnAuthenticated>
            }
          />
          <Route
            exact
            path="/accountInfo"
            element={
              <RenderOnAuthenticated>
                <AccountInfo />
              </RenderOnAuthenticated>
            }
          />
          <Route
            exact
            path="/accountInfo/agreements"
            element={
              <RenderOnAuthenticated>
                <Agreements />
              </RenderOnAuthenticated>
            }
          />
          <Route
            exact
            path="/agreements"
            element={
              <RenderOnAuthenticated>
                <Agreements />
              </RenderOnAuthenticated>
            }
          />
          <Route
            exact
            path="/cardAdmin"
            element={
              <RenderOnAuthenticated>
                <CardAdmin />
              </RenderOnAuthenticated>
            }
          />
          <Route
            exact
            path="/changeLanguage"
            element={
              <RenderOnAuthenticated>
                <ChangeLanguage />
              </RenderOnAuthenticated>
            }
          />
          <Route
            exact
            path="/confirmLanguage"
            element={
              <RenderOnAuthenticated>
                <ChangeLanguage confirm="yes" />
              </RenderOnAuthenticated>
            }
          />
          <Route
            exact
            path="/changePassword"
            element={
              <RenderOnAuthenticated>
                <ChangePassword />
              </RenderOnAuthenticated>
            }
          />
          <Route
            exact
            path="/settings/changePassword"
            element={
              <RenderOnAuthenticated>
                <ChangePassword />
              </RenderOnAuthenticated>
            }
          />
          <Route
            exact
            path="/onboarding"
            element={
              <RenderOnAnonymous>
                <MainOnboarding />
              </RenderOnAnonymous>
            }
          />
          <Route
            exact
            path="/onboarding/company/:companyId"
            element={<CompanyOnboarding />}
          />
          <Route
            exact
            path="/externalPayments"
            element={
              <RenderOnAnonymous>
                <MainExternalPayment />
              </RenderOnAnonymous>
            }
          />
          <Route
            exact
            path="/funds/receive/:token"
            element={
              <RenderOnAnonymous>
                <MainFundsReceive />
              </RenderOnAnonymous>
            }
          />
          <Route
            exact
            path="/errorPage"
            element={
              <RenderOnAnonymous>
                <ErrorPage />
              </RenderOnAnonymous>
            }
          />
          <Route exact path="/underReview" element={<CardNotFound />} />
          <Route exact path="*" element={<LoginPage />} />
        </Routes>
      </BrowserRouter>
    </LocalizationProvider>
  );
}
